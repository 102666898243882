body .employees-work-hours-tab .work-week-header h2 {
  text-align: center;
}
body .employees-work-hours-tab .work-hours-week-form .work-hours-week-form-hours-week {
  margin-bottom: 15px;
  font-size: 12pt;
  line-height: 18pt;
}
body .employees-work-hours-tab .work-hours-week-form .ant-row .text-cell {
  padding-top: 8px;
  text-align: right;
}
body .employees-work-hours-tab .work-hours-week-nav .ant-btn {
  padding: 4px 8px;
}
body .employees-work-hours-tab .register-time-registration {
  margin-top: 15px;
  width: 100%;
}
body .employees-work-hours-tab .work-hours-weekday {
  text-align: right;
}
body .employees-work-hours-tab .ant-form .ant-row > div {
  padding: 0 1px;
}
body .employees-work-hours-tab .ant-form .ant-row > div.work-hours-weekday {
  padding-right: 4px;
}
body .employees-work-hours-tab .ant-card .ant-card-body .btn-group {
  text-align: center;
  margin-bottom: 10px;
}
body .employees-work-hours-tab .ant-card .ant-card-body .work-week-summary {
  text-align: center;
  font-size: 13pt;
  color: var(--sally-paragraph-text);
}
body .employees-work-hours-tab .ant-card .ant-card-body .work-week-summary span {
  font-weight: bold;
}
body .employees-work-hours-tab .ant-card .ant-card-body .work-week-template-button {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
body .employees-work-hours-tab .work-week-template {
  padding: 5px 10px;
}
body .employees-work-hours-tab .work-hours-week-form .ant-btn.ant-btn-primary {
  padding-left: 2px;
  padding-right: 2px;
}

