@charset "UTF-8";
body {
  --ageras-electric-green: #01ff94;
  --ageras-deep-sea-green: #002e33;
  --ageras-lavender: #dbd4ff;
  --ageras-day-grey: #edf1f7;
  --ageras-black: #000;
  --ageras-white: #fff;
  --ageras-green: #00e383;
  --ageras-green-140: #075e42;
  --ageras-green-120: #078e64;
  --ageras-green-110: #298574;
  --ageras-green-90: #32d583;
  --ageras-green-60: #6ce9a6;
  --ageras-green-20: #a6f4c5;
  --ageras-green-10: #d1fadf;
  --ageras-green-5: #e8fff0;
  --ageras-yellow: #fbdb3f;
  --ageras-yellow-220: #735714;
  --ageras-yellow-200: #8f7638;
  --ageras-yellow-120: #dcb11a;
  --ageras-yellow-30: #fff888;
  --ageras-yellow-10: #fff9cc;
  --ageras-red: #d92d20;
  --ageras-red-120: #951307;
  --ageras-red-110: #c94337;
  --ageras-red-100: #f4594e;
  --ageras-red-90: #f07870;
  --ageras-red-10: #f7ddd9;
  --ageras-red-5: #fff8f6;
  --ageras-purple: #5e45d2;
  --ageras-purple-110: #5d4dae;
  --ageras-purple-20: #f8f6ff;
  --ageras-blue-140: #131c3e;
  --ageras-blue-120: #1e267d;
  --ageras-blue-110: #2258ce;
  --ageras-blue-90: #5e8bef;
  --ageras-blue-60: #7ca2f8;
  --ageras-blue-10: #d0e6fe;
  --ageras-blue-5: #e4f1ff;
  --ageras-grey-200: #26282f;
  --ageras-grey-90: #697588;
  --ageras-grey-60: #a7a9b4;
  --ageras-grey-50: #d3d6df;
  --ageras-grey-40: #d9dce5;
  --ageras-grey-30: #e8e9f0;
  --ageras-grey-20: #f5f6f9;
  --ageras-grey-10: #f6f9ff;
  --sally-green: #24973c;
  --sally-green-shaded: #1f8234;
  --sally-green-faded: #6acb7e;
  --sally-orange: #f69306;
  --sally-orange-shaded: #f8750c;
  --sally-orange-faded: #ffa72c;
  --sally-yellow: #f4b711;
  --sally-yellow-shaded: #f2ae0a;
  --sally-yellow-faded: #ffce3d;
  --sally-red: #fa581f;
  --sally-red-shaded: #fc6234;
  --sally-red-faded: #f46e65;
  --sally-grey: #adbbc8;
  --sally-grey-shaded: #748da6;
  --sally-grey-faded: #cdcdcd;
  --sally-blue: #108ee9;
  --sally-blue-shaded: #0e77ca;
  --sally-blue-faded: #67b3ff;
  --sally-black: #20445b;
  --sally-black-faded: #3e5461;
  --sally-white: #fff;
  --sally-white-shaded: #e5e5e5;
  --sally-white-faded: #f7f7f7;
  font-family: var(--sally-font-family), sans-serif;
  /*@media only screen and (max-width: 940px) and (min-width: 320px) {
      .main-wrapper {
          width: 100%;
          border-radius: 0;
      }
  }*/
}
body .main-wrapper {
  position: fixed;
  top: 74px;
  top: calc(74px + env(safe-area-inset-top));
  right: 0;
  bottom: 62px;
  left: 0;
  overflow-y: scroll;
  z-index: 5;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding: 20px;
  background-color: var(--sally-background);
}
body .main-wrapper.no-footer {
  bottom: 0;
}
body .main-wrapper > div {
  margin-right: auto;
  margin-left: auto;
  max-width: 768px;
}
body .main-wrapper > div .ptr {
  position: relative;
}
body .main-wrapper > div .ptr .ptr-loader {
  position: absolute;
  top: -50px;
  left: 50%;
  display: none;
  width: 20px;
  margin-left: -10px;
}
body .main-wrapper > div .ptr.ptr-pulling .ptr-loader, body .main-wrapper > div .ptr.ptr-refreshing .ptr-loader {
  display: block;
}
body .main-wrapper .ant-row, body .main-wrapper .ant-row-flex {
  margin: 0 -15px;
}
body .main-wrapper .ant-row > div, body .main-wrapper .ant-row-flex > div {
  padding: 0 15px;
}
body .main-wrapper .ant-row + .ant-row, body .main-wrapper .ant-row + .ant-row-flex, body .main-wrapper .ant-row-flex + .ant-row, body .main-wrapper .ant-row-flex + .ant-row-flex {
  margin-top: 30px;
}
body .main-wrapper .release-info {
  margin: 20px 0 10px 0;
  text-align: center;
  font-size: 11px;
  color: #999;
}
body .main-wrapper.platform-ios {
  bottom: 92px;
}
body .login-wrapper {
  position: fixed;
  top: 74px;
  top: calc(74px + env(safe-area-inset-top));
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  padding: 20px;
  background-color: #eff3f6;
}
body .login-wrapper > div {
  margin-right: auto;
  margin-left: auto;
  max-width: 768px;
}
body .login-wrapper .release-info {
  margin: 20px 0 10px 0;
  text-align: center;
  font-size: 11px;
  color: #999;
}
body .ant-card {
  margin-bottom: 0;
}
body .ant-card.ant-card-bordered, body .ant-card.ant-card-bordered:hover {
  border: 1px solid #dfe2e5;
  border-radius: 4px;
  box-shadow: none;
}
body .ant-card.ant-card-bordered .ant-card-body, body .ant-card.ant-card-bordered:hover .ant-card-body {
  padding: 30px;
}
body .ant-card + .ant-card {
  margin-top: 30px;
}
body .ant-form .form-error {
  margin-bottom: 5px;
  padding-left: 20px;
  background-image: url("/src/images/icon-cross-red-20x20.png");
  background-position: -4px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  line-height: 20px;
  font-size: 14px;
  color: var(--sally-red);
}
body .ant-form .ant-row {
  margin-right: -10px;
  margin-left: -10px;
}
body .ant-form .ant-row > div {
  padding: 0 10px;
}
body .ant-form .ant-row > div > .ant-row {
  margin-right: 0;
  margin-left: 0;
}
body .ant-form .ant-row > div > .ant-row > div {
  padding: 0;
}
body .ant-form .ant-row + .ant-row {
  margin-top: 15px;
}
body .ant-form .ant-form-item {
  margin-bottom: 10px;
}
body .ant-form .ant-form-item .ant-form-item-control {
  line-height: inherit;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-form-extra {
  display: block;
  margin-top: 10px;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-input-number {
  margin-top: 0;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-switch, body .ant-form .ant-form-item .ant-form-item-control .ant-radio-group {
  margin-top: 9px;
  margin-bottom: 9px;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-field-wrapper {
  position: relative;
  width: 100%;
  height: 38px;
  background-color: #fff;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-field-wrapper > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: transparent;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-field-wrapper > *.ant-form-suffix {
  bottom: 0;
}
body .ant-form .ant-form-item .ant-form-item-control .ant-form-suffix, body .ant-form .ant-form-item .ant-form-item-control .ant-field-wrapper.ant-form-suffix {
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 5px;
  left: auto;
  z-index: 1;
  height: 36px;
  line-height: 36px;
  color: var(--sally-input-placeholder-text);
}
body .ant-form .ant-form-item.ant-form-item-hide-error .ant-form-item-control-wrapper .ant-form-item-control.has-error label {
  color: var(--sally-input-placeholder-text);
}
body .ant-form .ant-form-item.ant-form-item-hide-error .ant-form-item-control-wrapper .ant-form-item-control.has-error .ant-input {
  border-color: var(--sally-input-border);
}
body .ant-form .ant-form-item.ant-form-item-hide-error .ant-form-item-control-wrapper .ant-form-item-control.has-error .ant-input-group-addon {
  border-color: var(--sally-input-border);
  color: var(--sally-input-text);
}
body .ant-form .ant-form-item.ant-form-item-hide-error .ant-form-item-control-wrapper .ant-form-item-control.has-error .ant-input-wrapper .ant-input {
  border-color: var(--sally-input-border);
}
body .ant-form .ant-form-item.ant-form-item-hide-error .ant-form-item-control-wrapper .ant-form-item-control.has-error .ant-input-wrapper:not(.ant-input-group):after {
  display: none;
}
body .ant-form .ant-form-extra {
  color: var(--sally-input-placeholder-text);
}
body .ant-form .ant-btn[type=submit] {
  display: block;
  width: 100%;
}
body .ant-form .ant-btn + .ant-btn {
  display: inline-block;
  margin-left: 16px;
}
body .ant-form .ant-form-warning {
  display: inline-block;
  padding: 2px 0 2px 35px;
  background-image: url("/src/images/icon-circle-info-orange-22x22.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  font-weight: 500;
  color: var(--sally-input-error-text);
}
body .ant-form.show-detailed-errors .has-error .ant-form-explain {
  display: block;
}
body .ant-input-group.ant-input-group-compact > * {
  width: auto;
}
body .ant-input-group.ant-input-group-compact > *:first-child {
  margin-right: 0;
}
body .ant-input-group.ant-input-group-compact > *:last-child {
  margin-left: 0;
}
body .ant-input-group.ant-input-group-compact.country-code-phone-group {
  display: flex;
  align-content: stretch;
  width: 100%;
}
body .ant-input-group.ant-input-group-compact.country-code-phone-group > * {
  display: block;
}
body .ant-input-group.ant-input-group-compact.country-code-phone-group > *:first-child {
  width: 80px;
}
body .ant-input-group.ant-input-group-compact.country-code-phone-group > *:last-child {
  flex: 1 0 auto;
  width: auto;
}
body .ant-input-group-addon {
  padding: 4px 5px 4px 0;
  background-color: var(--sally-input-background);
  border: 1px solid var(--sally-input-border);
  font-size: 14px;
  color: var(--sally-input-text);
}
body .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  background-color: var(--sally-input-background);
  border-color: var(--sally-input-border);
}
body .ant-checkbox .ant-checkbox-inner:after {
  top: 2px;
  left: 5px;
}
body .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner, body .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-border);
}
body label {
  display: block;
  margin-bottom: 3px;
  font-size: 14px;
  color: var(--sally-input-text);
}
body label > em {
  font-style: normal;
  color: var(--sally-input-placeholder-text);
}
body .ant-input-wrapper .ant-input-group-addon {
  padding: 4px 7px;
}
body .ant-input-group-lg .ant-input, body .ant-input {
  height: 38px;
  padding: 8px 15px;
  border: 1px solid var(--sally-input-border);
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-input-text);
}
body .ant-input-group-lg .ant-input:hover, body .ant-input:hover {
  border-color: var(--sally-input-hover-border);
  box-shadow: 0 1px 2px 0 var(--sally-input-focus-shadow) inset;
}
body .ant-input-group-lg .ant-input:focus, body .ant-input:focus {
  border-color: var(--sally-input-focus-border);
  font-weight: 500;
  color: var(--sally-input-text);
}
body .ant-input-number {
  width: 100%;
  height: 38px;
  border: 1px solid var(--sally-input-border);
}
body .ant-input-number .ant-input-number-handler-wrap {
  z-index: 3;
  width: 25px;
  background-color: var(--sally-input-background);
  border-left: 1px solid var(--sally-input-border);
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  height: 19px;
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  left: 5px;
  color: var(--sally-input-placeholder-text);
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner:before, body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner:before {
  font-family: inherit !important;
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
  font-size: 28px;
  font-weight: 400;
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner:before {
  content: "+";
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 20px;
  font-weight: 700;
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner:before {
  content: "—";
}
body .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler:hover {
  height: 19px !important;
}
body .ant-input-number .ant-input-number-input-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
body .ant-input-number .ant-input-number-input-wrap .ant-input-number-input {
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-input-text);
}
body .ant-input-number.ant-input-number-no-handler .ant-input-number-handler-wrap {
  display: none;
}
body .ant-input-number:hover {
  border-color: var(--sally-input-hover-border);
  box-shadow: 0 1px 2px 0 var(--sally-input-focus-shadow) inset;
}
body .ant-input-number:focus {
  border-color: var(--sally-input-focus-border);
}
body .ant-input-number:focus .ant-input-number-input {
  font-weight: 500;
  color: var(--sally-input-text);
}
body .ant-cascader-picker .ant-cascader-picker-label {
  padding: 0 35px 0 15px;
  font-size: 14px;
  font-weight: 500;
  color: var(---sally-input-text);
}
body .ant-cascader-picker .ant-cascader-picker-arrow {
  right: 6px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background-image: url("/src/images/icon-arrows-grey-20x20.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
body .ant-cascader-picker .ant-cascader-picker-arrow:before {
  content: "";
}
body .ant-cascader-picker .ant-cascader-picker-label span,
body .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item span {
  display: inline-block;
  padding-right: 20px;
  padding-left: 6px;
  background-image: url("/src/images/icon-arrow-right-grey-12x12.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}
body .ant-cascader-picker .ant-cascader-picker-label span:first-child,
body .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item span:first-child {
  padding-left: 0;
}
body .ant-cascader-picker .ant-cascader-picker-label span:last-child,
body .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item span:last-child {
  padding-right: 0;
  background: none;
}
body .ant-switch-wrapper .ant-switch {
  float: left;
  margin-top: 9px;
  margin-right: 15px;
  margin-bottom: 9px;
}
body .ant-switch-wrapper .ant-switch-text {
  line-height: 38px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-toggle-text);
}
body .ant-switch-wrapper.ant-switch-disabled-wrapper .ant-switch-text {
  color: var(--sally-toggle-hover-text);
}
body .ant-switch {
  height: 20px;
  min-width: 38px;
  line-height: 18px;
  background-color: var(--sally-switch-background);
  border-color: var(--sally-switch-background);
  border-radius: 18px;
}
body .ant-switch .ant-switch-inner {
  margin-right: 6px;
  margin-left: 22px;
}
body .ant-switch.ant-switch-checked {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-background);
}
body .ant-switch.ant-switch-checked .ant-switch-inner {
  margin-right: 22px;
  margin-left: 6px;
}
body .ant-switch.ant-switch-checked:hover {
  background-color: var(--sally-toggle-checked-hover-background);
  border-color: var(--sally-toggle-checked-hover-background);
}
body .ant-switch.ant-switch-checked:after {
  margin-left: -17px;
}
body .ant-switch.ant-switch-disabled {
  background-color: var(--sally-switch-disabled-background);
  border-color: var(--sally-switch-disabled-background);
}
body .ant-switch.ant-switch-disabled:hover {
  background-color: var(--sally-switch-disabled-background);
  border-color: var(--sally-switch-disabled-background);
}
body .ant-switch.ant-switch-disabled:after {
  background-color: var(--sally-switch-background);
}
body .ant-switch:hover {
  background-color: var(--sally-switch-hover-background);
  border-color: var(--sally-switch-hover-background);
}
body .ant-switch:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}
body .ant-radio-wrapper {
  margin-right: 30px;
  font-size: 14px;
  color: var(--sally-switch-background);
}
body .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 16px;
  height: 16px;
  background-color: var(--sally-switch-nubbin);
  border-color: var(--sally-switch-nubbin-shadow);
}
body .ant-radio-wrapper .ant-radio .ant-radio-inner:after {
  top: 4px;
  left: 4px;
  background-color: transparent;
}
body .ant-radio-wrapper .ant-radio + * {
  padding-left: 13px;
  padding-right: 0;
}
body .ant-radio-wrapper .icon {
  margin-bottom: -5px;
}
body .ant-radio-wrapper.ant-radio-wrapper-checked {
  font-weight: 500;
  color: var(---sally-toggle-checked-text);
}
body .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-border);
}
body .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--sally-switch-nubbin);
}
body .ant-radio-wrapper:hover .ant-radio .ant-radio-inner {
  border-color: var(--sally-toggle-checked-border);
}
body .ant-radio-wrapper:last-child {
  margin-right: 0;
}
body .ant-calendar-picker .ant-calendar-range-picker.ant-input {
  padding-right: 34px;
}
body .ant-calendar-picker .ant-calendar-picker-icon {
  right: 8px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background-image: url("/src/images/icon-calendar-grey-20x20.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
body .ant-calendar-picker .ant-calendar-picker-icon:after {
  content: "";
}
body .ant-upload.ant-upload-drag {
  border: 0;
}
body .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border: 0;
}
body .has-error label {
  color: var(--sally-status-warning-text-on-white);
}
body .has-error .ant-input-wrapper {
  position: relative;
}
body .has-error .ant-input-wrapper .ant-input {
  border-color: var(--sally-status-failure-border);
}
body .has-error .ant-input-wrapper:not(.ant-input-group):after {
  content: "";
  position: absolute;
  top: 9px;
  right: 10px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("/src/images/icon-cross-red-20x20.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
body .has-error .ant-input-group .ant-input {
  border-color: var(--sally-status-failure-border);
}
body .has-error .ant-form-explain {
  display: none;
  margin-top: 5px;
  font-size: 14px;
  color: var(--sally-status-failure-border);
}
body .ant-alert {
  padding: 12px 44px;
  margin-bottom: 30px;
  background-position: 12px center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-status-failure-text);
}
body .ant-alert > .anticon {
  display: none;
}
body .ant-alert .ant-alert-close-icon {
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-image: url("/src/images/icon-cross-white-10x10.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}
body .ant-alert .ant-alert-close-icon > .anticon {
  display: none;
}
body .btn-group .ant-btn {
  padding-right: 14px;
  border-color: var(--sally-button-secondary-focus-border);
}
body .btn-group .ant-btn.ant-btn-secondary {
  font-size: 12px;
}
body .btn-group *:first-child .ant-btn, body .btn-group *.ant-btn:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .btn-group *:last-child.ant-btn, body .btn-group *.ant-btn:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.registrations-list div.registration-row .ant-card.ant-card-bordered.registration-row-unapproved .ant-card-body, .registrations-list div.registration-row .ant-card.ant-card-bordered:hover.registration-row-unapproved .ant-card-body {
  border-color: var(--sally-status-awaiting-system-border);
}
.registrations-list div.registration-row .ant-card.ant-card-bordered.registration-row-approved .ant-card-body, .registrations-list div.registration-row .ant-card.ant-card-bordered:hover.registration-row-approved .ant-card-body {
  border-color: var(--sally-status-success-border);
}
.registrations-list div.registration-row .ant-card.ant-card-bordered .ant-card-body, .registrations-list div.registration-row .ant-card.ant-card-bordered:hover .ant-card-body {
  position: relative;
  padding: 0 20px 0 0;
  border-left: 6px solid transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.registrations-list div.registration-row .ant-card.ant-card-bordered .ant-card-body .registration-row-info, .registrations-list div.registration-row .ant-card.ant-card-bordered:hover .ant-card-body .registration-row-info {
  padding: 15px 0 15px 14px;
}
.registrations-list div.registration-row .ant-card.ant-card-bordered .ant-card-body .registration-row-info:not(.no-click), .registrations-list div.registration-row .ant-card.ant-card-bordered:hover .ant-card-body .registration-row-info:not(.no-click) {
  cursor: pointer;
}
.registrations-list div.registration-row .ant-card.ant-card-bordered .ant-card-body .registration-row-delete, .registrations-list div.registration-row .ant-card.ant-card-bordered:hover .ant-card-body .registration-row-delete {
  position: absolute;
  top: 50%;
  right: 5px;
  visibility: visible;
  display: block;
  width: 61px;
  height: 61px;
  margin-top: -30px;
  background-image: url("/src/images/icon-cross-grey-11x11.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  cursor: pointer;
}
.registrations-list div.registration-row + div.registration-row .ant-card.ant-card-bordered {
  margin-top: 20px;
}

.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft {
  border: 1px solid var(--sally-input-border);
  box-shadow: var(--sally-input-focus-shadow);
  font-size: 14px;
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu {
  border-right: 1px solid #dae1e8;
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item {
  padding: 8px 30px 8px 15px;
  border-bottom: 1px solid var(--sally-input-border);
  font-weight: 500;
  color: var(--sally-input-text);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item:after {
  content: " ";
  top: 13px;
  right: 10px;
  transform: scale(1);
  width: 12px;
  height: 12px;
  background-image: url("/src/images/icon-arrow-right-grey-12x12.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  background-color: var(--sally-input-background);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item:hover {
  background-color: var(--sally-input-background);
}

.registrations-add {
  position: fixed;
  right: 0;
  bottom: 62px;
  left: 0;
  height: 50px;
  width: 100%;
  border: none;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 0%;
}
.registrations-add:hover {
  text-decoration: underline;
}

.platform-ios .registrations-add {
  bottom: 92px;
}

