.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  display: flex;
  background: var(--sally-background-layer-1);
  border-top: 1px solid var(--sally-code-border);
  box-shadow: 0 -2px 6px 0 rgba(28, 75, 110, 0.04);
  width: 100%;
  height: 62px;
}
.footer.platform-ios {
  height: 92px;
}
.footer.platform-ios .nav-item {
  margin-bottom: 30px;
}

.nav-popover-menu .ant-popover-arrow {
  display: none;
}
.nav-popover-menu .ant-popover-inner {
  position: relative;
  bottom: -11px;
}
.nav-popover-menu .ant-popover-inner .ant-popover-inner-content {
  display: flex;
  flex-direction: column;
}

.footer .nav-item, .footer .nav-popover-item, .nav-popover-menu .nav-item, .nav-popover-menu .nav-popover-item {
  display: block;
  width: 33.33%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: var(--sally-navigator-text);
  cursor: pointer;
}
.footer .nav-item.nav-popover-item, .footer .nav-popover-item.nav-popover-item, .nav-popover-menu .nav-item.nav-popover-item, .nav-popover-menu .nav-popover-item.nav-popover-item {
  min-width: 150px;
  text-align: left;
  padding: 10px 0;
}
.footer .nav-item.nav-popover-item::before, .footer .nav-popover-item.nav-popover-item::before, .nav-popover-menu .nav-item.nav-popover-item::before, .nav-popover-menu .nav-popover-item.nav-popover-item::before {
  display: inline-block;
  float: left;
}
.footer .nav-item.nav-popover-item span, .footer .nav-popover-item.nav-popover-item span, .nav-popover-menu .nav-item.nav-popover-item span, .nav-popover-menu .nav-popover-item.nav-popover-item span {
  margin-top: 12px;
  padding-left: 15px;
  display: inline-block;
}
.footer .nav-item.nav-item-toggle, .footer .nav-popover-item.nav-item-toggle, .nav-popover-menu .nav-item.nav-item-toggle, .nav-popover-menu .nav-popover-item.nav-item-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer .nav-item.nav-item-toggle .nav-toggle-circle, .footer .nav-popover-item.nav-item-toggle .nav-toggle-circle, .nav-popover-menu .nav-item.nav-item-toggle .nav-toggle-circle, .nav-popover-menu .nav-popover-item.nav-item-toggle .nav-toggle-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 24px 10px 3px 10px;
  border: 2px solid var(--sally-navigator-text);
  border-radius: 50%;
  background-color: transparent;
}
.footer .nav-item.nav-item-toggle.ant-popover-open .nav-toggle-circle, .footer .nav-popover-item.nav-item-toggle.ant-popover-open .nav-toggle-circle, .nav-popover-menu .nav-item.nav-item-toggle.ant-popover-open .nav-toggle-circle, .nav-popover-menu .nav-popover-item.nav-item-toggle.ant-popover-open .nav-toggle-circle {
  border-color: var(--sally-link);
  background-color: var(--sally-link);
}
.footer .nav-item:not(.nav-item-toggle)::before, .footer .nav-item .nav-toggle-circle, .footer .nav-popover-item:not(.nav-item-toggle)::before, .footer .nav-popover-item .nav-toggle-circle, .nav-popover-menu .nav-item:not(.nav-item-toggle)::before, .nav-popover-menu .nav-item .nav-toggle-circle, .nav-popover-menu .nav-popover-item:not(.nav-item-toggle)::before, .nav-popover-menu .nav-popover-item .nav-toggle-circle {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: 12px auto 3px auto;
  border: 2px solid var(--sally-navigator-text);
  border-radius: 50%;
  background-color: transparent;
}
.footer .nav-item.nav-item-active, .footer .nav-popover-item.nav-item-active, .nav-popover-menu .nav-item.nav-item-active, .nav-popover-menu .nav-popover-item.nav-item-active {
  color: var(--sally-link);
}
.footer .nav-item.nav-item-active:before, .footer .nav-popover-item.nav-item-active:before, .nav-popover-menu .nav-item.nav-item-active:before, .nav-popover-menu .nav-popover-item.nav-item-active:before {
  border-color: var(--sally-link);
  background-color: var(--sally-link);
}

