.pay-slips {
  padding-top: 10px;
}
.pay-slips .btn-group {
  margin-top: -10px;
  margin-bottom: 20px;
  text-align: center;
}
.pay-slips .pay-slips-header {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
.pay-slips > div + div .pay-slips-header, .pay-slips > div > div + div .pay-slips-header {
  margin-top: 30px;
}
.pay-slips a, .pay-slips a:hover {
  text-decoration: none;
}
.pay-slips a .ant-card.ant-card-bordered .ant-card-body, .pay-slips a .ant-card.ant-card-bordered:hover .ant-card-body, .pay-slips a:hover .ant-card.ant-card-bordered .ant-card-body, .pay-slips a:hover .ant-card.ant-card-bordered:hover .ant-card-body {
  position: relative;
  padding: 15px 20px;
}
.pay-slips a .ant-card.ant-card-bordered .ant-card-body:after, .pay-slips a .ant-card.ant-card-bordered:hover .ant-card-body:after, .pay-slips a:hover .ant-card.ant-card-bordered .ant-card-body:after, .pay-slips a:hover .ant-card.ant-card-bordered:hover .ant-card-body:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  visibility: visible;
  display: block;
  width: 7px;
  height: 13px;
  margin-top: -6px;
  background-image: url("/src/images/icon-arrow-right-grey-7x13.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 7px 13px;
}
.pay-slips a + a .ant-card.ant-card-bordered, .pay-slips a:hover + a .ant-card.ant-card-bordered {
  margin-top: 20px;
}
.pay-slips .pay-slip-amount {
  font-size: 17px;
  font-weight: 500;
  color: var(--sally-text);
}
.pay-slips .pay-slip-period {
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
.pay-slips .pay-slip-period > * {
  display: inline-block;
  margin-left: 20px;
}
.pay-slips .pay-slip-period > *:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 14px;
  margin-left: 20px;
  background-image: url("/src/images/icon-arrow-right-blue-20x20.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 18px 10px;
}
.pay-slips .pay-slip-period > *:first-child {
  margin-left: 0;
}
.pay-slips .pay-slip-period > *:last-child:after {
  display: none;
}
.pay-slips .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}

