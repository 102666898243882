/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
body .ant-alert {
  position: relative;
  padding: 8px 48px 8px 38px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.5;

  color: var(--sally-text);

  .icon-fill {
    fill: var(--sally-text);
  }

  a {
    text-decoration: underline;
  }

  &.ant-alert-no-icon {
    padding: 8px 48px 8px 16px;
  }

  .ant-alert-icon {
    font-size: 14px;
    top: 15px;
    left: 16px;
    position: absolute;
  }

  .ant-alert-description {
    font-size: 12px;
    line-height: 21px;
    display: none;
  }

  &.ant-alert-success {
    border: 1px solid var(--sally-status-success-background);
    background-color: var(--sally-status-success-border);

    &,
    a {
      color: var(--sally-status-success-text);
    }

    .icon-fill {
      fill: var(--sally-status-success-text);
    }
  }

  &.ant-alert-info {
    border: 1px solid var(--sally-status-awaiting-system-background);
    background-color: var(--sally-status-awaiting-system-border);

    &,
    a {
      color: var(--sally-status-awaiting-system-text);
    }

    .icon-fill {
      fill: var(--sally-status-awaiting-system-text);
    }
  }

  &.ant-alert-warning {
    border: 1px solid var(--sally-status-warning-background);
    background-color: var(--sally-status-warning-border);

    &,
    a {
      color: var(--sally-status-warning-text);
    }

    .icon-fill {
      fill: var(--sally-status-warning-text);
    }
  }

  &.ant-alert-error {
    border: 1px solid var(--sally-status-failure-background);
    background-color: var(--sally-status-failure-border);

    &,
    a {
      color: var(--sally-status-failure-text);
    }

    .icon-fill {
      fill: var(--sally-status-failure-text);
    }
  }

  .ant-alert-close-icon {
    font-size: 12px;
    position: absolute;
    right: 16px;
    top: 10px;
    height: 12px;
    line-height: 12px;
    overflow: hidden;
    cursor: pointer;
  }

  .ant-alert-close-text {
    position: absolute;
    right: 16px;
  }

  &.ant-alert-close {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }

  &+.ant-alert {
    margin-top: 20px;
  }
}

.ant-alert-slide-up-leave {
  animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}

.ant-alert-banner {
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
}

@keyframes antAlertSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes antAlertSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

/*
body {
  .ant-alert {
    padding: 12px 44px;
    margin-bottom: 30px;
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 22px 22px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;

    > .anticon {
      display: none;
    }
    .ant-btn {
      min-height: auto;
      height: auto;
      line-height: 18px;
      margin-left: 20px;
      padding: 0px 14px;

      &.ant-btn-regular {
        margin-top: -7px;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
    .ant-alert-close-icon {
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 10px 10px;
      cursor: pointer;

      > .anticon {
        display: none;
      }
    }
    &.ant-alert-success {
      border-color: #14a633;
      background-color: #14a633;
      box-shadow: 0 2px 4px 0 rgba(3, 138, 31, 0.12);
    }
    &.ant-alert-warning {
      border-color: var(--sally-orange);
      background-color: var(--sally-orange);
      box-shadow: 0 2px 4px 0 rgba(212, 120, 9, 0.12);
      a {
        color: #404040;
      }
    }
    &.ant-alert-error {
      border-color: var(--sally-red);
      background-color: var(--sally-red);
      box-shadow: 0 2px 4px 0 rgba(217, 63, 9, 0.12);
      a {
        color: #bacfd4;
      }
    }
  }
}
*/