.loading-overlay {
    &:not(.inline) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--sally-background-layer-1);

    &.inline {
        & ~ * {
            display: none !important;
        }
    }

    .loading-text {
        text-align: center;
        margin: 0 10%;
    }
}
