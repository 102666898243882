.fees {
  padding-bottom: 50px;

  .fees-header {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #778da0;
  }
  & > div, & > div > div {
    & + div {
      .fees-header {
        margin-top: 30px;
      }
    }
  }
  .fee-date, .fee-title {
    font-size: 14px;
    font-weight: 300;
    color: #758ca0;
  }
  .fee-amount {
    font-size: 17px;
    font-weight: 500;
    color: var(--sally-black);
  }
  .fee-delete {
    position: absolute;
    top: 50%;
    right: 5px;
    visibility: visible;
    display: block;
    width: 61px;
    height: 61px;
    margin-top: -30px;
    background-image: url('/src/images/icon-cross-grey-11x11.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 11px 11px;
  }
  .content-text {
    margin-bottom: 20px;
    text-align: center;
    font-size: 12px;
    color: #778da0;
  }
  .fees-add {
    position: fixed;
    right: 0;
    bottom: 62px;
    left: 0;
    height: 50px;
    background-color: #ffa800;
    line-height: 50px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
  }
  &.platform-ios {
    .fees-add {
      bottom: 92px;
    }
  }
}