/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.react-datepicker-container {
  .react-datepicker-input-wrapper {
    border: 1px solid var(--sally-datepicker-border);
    font-size: 14px;
    font-weight: 500;
    color: var(--sally-datepicker-text);
    height: 38px;
    padding: 8px 15px;
    border-radius: 4px;
    line-height: 1.5;

    &.react-datepicker-instructions {
      display: flex;
      justify-content: space-between;

      .react-datepicker-instructions-text {
        text-align: center;
        flex-grow: 2;
      }
    }

    input {
      border: none;
      outline: none;
      background-color: var(--sally-input-background);

      &:hover, &:focus-visible, &:focus {
        border: none;
        outline: none;
      }
    }

    &.react-datepicker-input-range {
      display: flex;
      justify-content: space-between;

      .picker-separator {
        margin: 0 15px;
      }

      input {
        text-align: center;
        max-width: 35%;
      }
    }

    .picker-icon {
      color: var(--sally-datepicker-text);
      float: right;
      width: 20px;
      height: 20px;
      background-image: url('/src/images/icon-calendar-grey-20x20.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }

    .picker-clear {
      opacity: 0;
      z-index: 1;
      color: var(--sally-datepicker-text);
      background: var(--sally-datepicker-background);
      pointer-events: none;
      cursor: pointer;

      &::after {
        content: "\e62e";
      }
    }
  }

  .react-datepicker-popper {
    position: absolute;
    z-index: 1050;
    background-clip: padding-box;
    background-color: var(--sally-datepicker-background);
    border: 1px solid var(--sally-datepicker-border);
    border-radius: 4px;
    box-shadow: 0 1px 6px var(--sally-datepicker-shadow);
    font-size: 12px;
    line-height: 1.5;
    list-style: none;
    outline: none;
    text-align: left;

    &.two-months-shown {
      width: 504px;
      left: calc((100% - 504px) /2) !important;
    }
    &:not(.two-months-shown) {
      width: 252px;
      left: calc((100% - 252px) / 2) !important;
    }

    .react-datepicker__header {
      .react-datepicker__header-wrapper {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        width: 251px;

        .react-datepicker__navigation {
          color: var(--sally-datepicker-text);
          font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
          padding: 0 5px;
          font-size: 16px;
          display: inline-block;
          line-height: 24px;
          background: none;
          border: none;
          cursor: pointer;
          width: 50px;
          z-index: 1051;

          span {
            display: none;
          }

          &.react-datepicker__navigation--previous:before {
            content: '‹';
          }

          &.react-datepicker__navigation--next:after {
            content: '›';
          }

          &:hover {
            color: var(--sally-datepicker-day-hover-text);
            text-decoration: underline;
          }
        }

        .datepicker-select {
          background-color: var(--sally-datepicker-background);
          border-radius: 4px;
          border: 1px solid var(--sally-datepicker-border);
        }
      }
    }

    .react-datepicker__month-container {
      float: left;

      .react-datepicker__header {
        text-align: center;
        position: relative;

        .react-datepicker__current-month {
          color: var(--sally-datepicker-text);
          display: inline-block;
          font-weight: 700;
          line-height: 24px;
          padding: 0 2px;
        }

        .react-datepicker__header__dropdown {
          padding: 2px 30px 4px;

          .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
            margin-bottom: 4px;

            &.react-datepicker__month-dropdown-container {
              float: left;
            }
            &.react-datepicker__year-dropdown-container {
              float: right;
            }
            select {
              background: var(--sally-datepicker-background);
              border-radius: 2px;
              border: 1px solid var(--sally-datepicker-border);
            }
          }
        }

        .react-datepicker__day-names {
          border-top: 1px solid var(--sally-datepicker-border);
          clear: both;
        }
      }
    }

    .react-datepicker__day, .react-datepicker__day-name {
      display: inline-block;
      width: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      margin: .166rem;

      &.react-datepicker__day--outside-month { 
        color: var(--sally-datepicker-outside-month);
      }
    }

    .react-datepicker__day-name {
      color: var(--sally-datepicker-text);
    }

    .react-datepicker__day-name:first-child,.react-datepicker__week-number {
      display: inline-block;
      width: 1.2rem;
      line-height: 1.2rem;
      text-align: center;
      margin: .1rem;
      font-size: 10px;
      color: var(--sally-datepicker-week-number);
    }

    .react-datepicker__day {
      color: var(--sally-datepicker-text);
      cursor: pointer;
      border-radius: 16px;

      &:hover, &.react-datepicker__day--in-selecting-range {
        background: var(--sally-datepicker-day-hover-background);
      }

      &.react-datepicker__day--selected, &.react-datepicker__day--in-range {
        color: var(--sally-datepicker-day-hover-text);
        background: var(--sally-datepicker-day-selected-background);
      }

      &.react-datepicker__day--today {
        border: 1px var(--sally-datepicker-day-selected-background) solid;
        font-weight: bold;
      }

      &.react-datepicker__day--disabled {
        color: var(--sally-grey-faded);
        font-weight: normal;
        border: 0;
        cursor: not-allowed;

        &:hover {
          background: inherit;
        }
      }
    }

    .react-datepicker__today-button {
      border-top: 1px solid #e9e9e9;
      color: var(--sally-datepicker-text) ;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      clear: both;
      padding-top: 10px;
      height: 40px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}