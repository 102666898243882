/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-select {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 8px;
  line-height: 1;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.43);
  display: inline-block;
  font-size: 12px;
  font-size: 9px \9;
  -ms-transform: scale(0.75) rotate(0deg);
      transform: scale(0.75) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
}
.ant-select-arrow:before {
  display: block;
  font-family: "anticon" !important;
}
:root .ant-select-arrow {
  -webkit-filter: none;
          filter: none;
}
:root .ant-select-arrow {
  font-size: 12px;
}
.ant-select-arrow * {
  display: none;
}
.ant-select-arrow:before {
  content: '\e61d';
  transition: transform 0.2s ease;
}
.ant-select-selection {
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection:hover {
  border-color: var(--sally-blue-faded);
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: var(--sally-blue-faded);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
}
.ant-select-selection__clear {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  opacity: 0;
  position: absolute;
  right: 8px;
  z-index: 1;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-select-selection__clear:before {
  display: block;
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.43);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-right: 14px;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f7f7f7;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  background: #eee;
  color: #aaa;
  padding-right: 10px;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  height: 28px;
  position: relative;
  cursor: pointer;
}
.ant-select-selection__rendered {
  display: block;
  margin-left: 7px;
  margin-right: 7px;
  position: relative;
  line-height: 26px;
}
.ant-select-selection__rendered:after {
  content: '.';
  visibility: hidden;
  pointer-events: none;
  display: inline-block;
  width: 0;
}
.ant-select-lg .ant-select-selection--single {
  height: 32px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 30px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 24px;
  line-height: 24px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear {
  top: 16px;
}
.ant-select-sm .ant-select-selection--single {
  height: 22px;
}
.ant-select-sm .ant-select-selection__rendered {
  line-height: 20px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 14px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear {
  top: 11px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  display: inline-block;
  position: relative;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  right: 9px;
  color: #bfbfbf;
  line-height: 20px;
  height: 20px;
  max-width: 100%;
  margin-top: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.ant-select-search__field__placeholder {
  left: 8px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: -9999px;
  white-space: pre;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ant-select-selection--multiple .ant-select-search--inline {
  float: left;
  position: static;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  border-width: 0;
  font-size: 100%;
  height: 100%;
  width: 100%;
  background: transparent;
  outline: 0;
  border-radius: 4px;
  line-height: 1;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 28px;
  cursor: text;
  padding-bottom: 3px;
  zoom: 1;
}
.ant-select-selection--multiple:before,
.ant-select-selection--multiple:after {
  content: " ";
  display: table;
}
.ant-select-selection--multiple:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-select-selection--multiple .ant-select-search--inline {
  width: auto;
  padding: 0;
  max-width: 100%;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  max-width: 100%;
  width: 0.75em;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 5px;
  margin-bottom: -3px;
  height: auto;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  margin-top: 3px;
  height: 20px;
  line-height: 20px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f3f3f3;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 20px 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.43);
  line-height: inherit;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  -ms-transform: scale(0.66666667) rotate(0deg);
      transform: scale(0.66666667) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
  position: absolute;
  right: 4px;
  padding: 0 0 0 8px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:before {
  display: block;
  font-family: "anticon" !important;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  -webkit-filter: none;
          filter: none;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #404040;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:before {
  content: "\e633";
}
.ant-select-selection--multiple .ant-select-selection__clear {
  top: 14px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -ms-transform: rotate(180deg);
}
.ant-select-open .ant-select-arrow:before {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: var(--sally-blue-faded);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  height: 100%;
  width: 100%;
  float: none;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: none;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1050;
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
  overflow: hidden;
  font-size: 12px;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: 250px;
  overflow: auto;
}
.ant-select-dropdown-long .ant-select-dropdown-menu {
  max-height: 70vh;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 16px;
}
.ant-select-dropdown-menu-item-group-title {
  color: rgba(0, 0, 0, 0.43);
  line-height: 1.5;
  padding: 8px;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 7px 8px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #ecf6fd;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  background-color: #f7f7f7;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}
.ant-select-dropdown-menu-item-active {
  background-color: #ecf6fd;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  line-height: 0;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e632";
  color: transparent;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -ms-transform: scale(0.83333333) rotate(0deg);
      transform: scale(0.83333333) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  right: 8px;
  font-weight: bold;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
}
:root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  -webkit-filter: none;
          filter: none;
}
:root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  font-size: 12px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover:after {
  color: #ddd;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled:after {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:after,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover:after {
  color: var(--sally-blue);
  display: inline-block;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}

body {
  .ant-select {
    width: 100%;
    font-size: 14px;

    .ant-select-selection {
      height: 38px;
      border: 1px solid #dae1e8;

      .ant-select-selection__placeholder {
        height: 36px;
        margin-top: -18px;
        padding-left: 8px;
        line-height: 36px;
        color: #acbac7;
      }
      .ant-select-selection-selected-value {
        padding-right: 20px;
        padding-left: 8px;
        font-weight: 500;
        color: var(--sally-black);
      }
      .ant-select-selection__rendered {
        line-height: 36px;

        .ant-select-search__field {
          border: 0;
          box-shadow: none;
          font-weight: 500;
          color: var(--sally-black);
        }
      }
      .ant-select-arrow {
        right: 6px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background-image: url('/src/images/icon-arrows-grey-20x20.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px 20px;

        &:before {
          content: '';
        }
      }
    }
    &.ant-select-combobox {
      .ant-select-search__field {
        padding-left: 8px;
      }
    }
    &.ant-select-focused, &.ant-select-open {
      .ant-select-selection {
        border-color: var(--sally-orange);
        box-shadow: none;
      }
    }
    &.ant-select-lg .ant-select-selection--single {
      height: 38px;
    }
  }
  .has-error {
    .ant-select {
      .ant-select-selection {
        border-color: var(--sally-red);
      }
    }
  }
}

.ant-select-dropdown {
  &, &.ant-select-dropdown--single {
    border: 1px solid #dae1e8;
    box-shadow: 0 2px 4px 0 rgba(116, 141, 166, 0.12);
    font-size: 14px;

    .ant-select-dropdown-menu-item {
      padding: 8px 15px;
      border-bottom: 1px solid #dae1e8;
      font-weight: 500;
      color: var(--sally-black);

      &.ant-select-dropdown-menu-item-active {
        background-color: #f6f9fb;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
