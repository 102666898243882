/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-radio-group {
  display: inline-block;
  font-size: 12px;
}

.ant-radio-wrapper {
  font-size: 12px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-right: 8px;
  cursor: pointer;
}

.ant-radio {
  white-space: nowrap;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: text-bottom;
  cursor: pointer;
}

.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-focused .ant-radio-inner {
  border-color: var(--sally-toggle-focus-border);
}

.ant-radio-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--sally-toggle-focus-border);
  content: "";
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.ant-radio:hover:after,
.ant-radio-wrapper:hover .ant-radio:after {
  visibility: visible;
}

.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 14px;
  border-color: var(--sally-switch-nubbin-shadow);
  background-color: var(--sally-switch-nubbin);
  transition: all 0.3s;
}

.ant-radio-inner:after {
  position: absolute;
  width: 6px;
  height: 6px;
  left: 3px;
  top: 3px;
  border-radius: 4px;
  display: table;
  border-top: 0;
  border-left: 0;
  content: " ";
  background-color: var(--sally-toggle-checked-background);
  opacity: 0;
  -ms-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-radio-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--sally-toggle-focus-border);
}

.ant-radio-checked .ant-radio-inner:after {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-radio-disabled .ant-radio-inner {
  border-color: var(--sally-switch-nubbin-shadow) !important;
  background-color: var(--sally-switch-nubbin);
}

.ant-radio-disabled .ant-radio-inner:after {
  background-color: var(--sally-switch-disabled-background);
}

.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}

.ant-radio-disabled + span {
  color: var(--sally-switch-disabled-background);
  cursor: not-allowed;
}

span.ant-radio + * {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-radio-button-wrapper {
  margin: 0;
  height: 28px;
  line-height: 26px;
  color: var(--sally-toggle-text);
  display: inline-block;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid var(--sally-switch-nubbin-shadow);
  border-left: 0;
  background: var(--sally-switch-nubbin);
  padding: 0 16px;
  position: relative;
}

.ant-radio-button-wrapper a {
  color: var(--sally-toggle-text);
}

.ant-radio-button-wrapper > .ant-radio-button {
  margin-left: 0;
  display: block;
  width: 0;
  height: 0;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 32px;
  line-height: 30px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  height: 22px;
  line-height: 20px;
  padding: 0 12px;
}

.ant-radio-group-small .ant-radio-button-wrapper:first-child {
  border-radius: 2px 0 0 2px;
}

.ant-radio-group-small .ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  content: "";
  display: block;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: var(--sally-switch-background);
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid var(--sally-switch-background);
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}

.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-focused {
  color: var(--sally-toggle-hover-text);
  position: relative;
}

.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type=checkbox],
.ant-radio-button-wrapper input[type=radio] {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 0;
  height: 0;
}

.ant-radio-button-wrapper-checked {
  background: var(--sally-switch-nubbin);
  border-color: var(--sally-toggle-checked-border);
  color: var(--sally-toggle-checked-border);
  z-index: 1;
}

.ant-radio-button-wrapper-checked::before {
  background-color: var(--sally-toggle-checked-background) !important;
  opacity: 0.1;
}

.ant-radio-button-wrapper-checked:first-child {
  border-color: var(--sally-toggle-checked-background);
  box-shadow: none !important;
}

.ant-radio-button-wrapper-checked:hover {
  border-color: var(--sally-toggle-checked-border);
  color: var(--sally-toggle-hover-text);
}

.ant-radio-button-wrapper-checked:active {
  border-color: var(--sally-toggle-checked-border);
  color: var(--sally-toggle-checked-text);
}

.ant-radio-button-wrapper-disabled {
  border-color: var(--sally-switch-nubbin-shadow);
  background-color: var(--sally-switch-disabled-background);
  cursor: not-allowed;
  color: var(--sally-toggle-text);
}

.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  border-color: var(--sally-switch-nubbin-shadow);
  background-color: var(--sally-switch-disabled-background);
  color: var(--sally-toggle-text);
}

.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: var(--sally-switch-disabled-background);
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: var(--sally-switch-nubbin);
  background-color: var(--sally-switch-disabled-background);
  border-color: var(--sally-switch-nubbin-shadow);
  box-shadow: none;
}

@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
body .ant-radio-wrapper {
  margin-right: 30px;
  font-size: 14px;
  color: var(--sally-switch-background);
}
body .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 16px;
  height: 16px;
  background-color: var(--sally-switch-nubbin);
  border-color: var(--sally-switch-background);
}
body .ant-radio-wrapper .ant-radio .ant-radio-inner:after {
  top: 4px;
  left: 4px;
  background-color: transparent;
}
body .ant-radio-wrapper .ant-radio + * {
  padding-left: 13px;
  padding-right: 0;
}
body .ant-radio-wrapper .icon {
  margin-bottom: -5px;
}
body .ant-radio-wrapper.ant-radio-wrapper-checked {
  font-weight: 500;
  color: var(--sally-toggle-text);
}
body .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-border);
}
body .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--sally-switch-nubbin);
}
body .ant-radio-wrapper:hover .ant-radio .ant-radio-inner {
  border-color: var(--sally-toggle-checked-border);
}
body .ant-radio-wrapper:last-child {
  margin-right: 0;
}

