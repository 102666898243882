/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: inherit;
  color: var(--sally-form-text);
  border: 0;
  border-bottom: 1px solid var(--sally-form-border);
}
label {
  font-size: 12px;
}
input[type="search"] {
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 15px;
  font-size: 12px;
  line-height: 1.5;
  color: var(--sally-form-text);
}
label {
  position: relative;
}
label > .anticon {
  vertical-align: top;
  font-size: 12px;
}
.ant-form-item-required:before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  line-height: 1;
  font-size: 12px;
  color: var(--sally-status-failure-text-on-white);
}
.ant-form-hide-required-mark .ant-form-item-required:before {
  display: none;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled {
  cursor: not-allowed;
}
.ant-radio-inline.disabled,
.ant-radio-vertical.disabled,
.ant-checkbox-inline.disabled,
.ant-checkbox-vertical.disabled {
  cursor: not-allowed;
}
.ant-radio.disabled label,
.ant-checkbox.disabled label {
  cursor: not-allowed;
}
.ant-form-item {
  font-size: 12px;
  margin-bottom: 24px;
  color: var(--sally-form-text);
  vertical-align: top;
}
.ant-form-item-control > .ant-form-item:last-child,
.ant-form-item [class^="ant-col-"] > .ant-form-item:only-child {
  margin-bottom: -24px;
}
.ant-form-item-control {
  line-height: 31.9999px;
  position: relative;
  zoom: 1;
}
.ant-form-item-control:before,
.ant-form-item-control:after {
  content: " ";
  display: table;
}
.ant-form-item-control:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-form-item-with-help {
  margin-bottom: 6px;
}
.ant-form-item-label {
  text-align: right;
  vertical-align: middle;
  line-height: 32px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-form-item-label label {
  color: var(--sally-form-label);
}
.ant-form-item-label label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -0.5px;
}
.ant-form-item .ant-switch {
  margin: 4px 0;
}
.ant-form-item-no-colon .ant-form-item-label label:after {
  content: " ";
}
.ant-form-explain,
.ant-form-extra,
.ant-form-char-limit {
  color: var(--sally-form-text);
  line-height: 1.5;
}
.ant-form-extra,
.ant-form-char-limit {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 24px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 21px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form textarea.ant-input {
  height: auto;
}
form .ant-upload {
  background: transparent;
}
form input[type="radio"],
form input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  margin-left: 8px;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number {
  margin-right: 8px;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-select:only-child,
form .ant-cascader-picker:only-child {
  display: block;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form .ant-input-group-addon .ant-select:only-child,
form .ant-input-group-addon .ant-cascader-picker:only-child {
  display: inline-block;
}
form .ant-input-group-wrapper {
  position: relative;
  top: 2px;
}
form .ant-input-group-wrapper.ant-input-group-wrapper-lg {
  top: 0;
}
form .ant-input-group-wrapper.ant-input-group-wrapper-sm {
  top: 5px;
}
.ant-input-group-wrap .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group-wrap .ant-select-selection:hover {
  border-color: var(--sally-input-border);
}
.ant-input-group-wrap .ant-select-selection--single {
  margin-left: -1px;
  height: 32px;
  background-color: var(--sally-input-background);
}
.ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-left: 8px;
  padding-right: 25px;
  line-height: 30px;
}
.ant-input-group-wrap .ant-select-open .ant-select-selection {
  border-color: var(--sally-input-border);
  box-shadow: none;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  display: block;
  text-align: left;
  line-height: 1.5;
}
.ant-form-vertical .ant-form-item-label label:after,
.ant-col-24.ant-form-item-label label:after,
.ant-col-xl-24.ant-form-item-label label:after {
  display: none;
}
@media (max-width: 767px) {
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-xs-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-sm-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-md-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-lg-24.ant-form-item-label label:after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > div {
  display: inline-block;
  vertical-align: middle;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.ant-form-inline .ant-form-explain {
  position: absolute;
}
.has-success.has-feedback:after,
.has-warning.has-feedback:after,
.has-error.has-feedback:after,
.is-validating.has-feedback:after {
  position: absolute;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  z-index: 1;
}
.has-success.has-feedback:after {
  animation-name: diffZoomIn1 !important;
}
.has-error.has-feedback:after {
  animation-name: diffZoomIn2 !important;
}
.has-warning.has-feedback:after {
  animation-name: diffZoomIn3 !important;
}
.has-success.has-feedback:after {
  content: '\e630';
  color: var(--sally-status-success-text);
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: var(--sally-status-warning-text);
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  border-color: var(--sally-status-warning-border);
}
.has-warning .ant-input:focus {
  border-color: var(--sally-status-warning-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 191, 0, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: var(--sally-status-warning-border);
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: var(--sally-status-warning-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 191, 0, 0.2);
}
.has-warning .ant-input-prefix {
  color: var(--sally-status-warning-text);
}
.has-warning .ant-input-group-addon {
  color: var(--sally-status-warning-text);
  border-color: var(--sally-status-warning-border);
  background-color: var(--sally-status-warning-background);
}
.has-warning .has-feedback {
  color: var(--sally-status-warning-text);
}
.has-warning.has-feedback:after {
  content: '\e62c';
  color: var(--sally-status-warning-text);
}
.has-warning .ant-select-selection {
  border-color: var(--sally-status-warning-border);
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: var(--sally-status-warning-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 191, 0, 0.2);
}
.has-warning .ant-calendar-picker-icon:after,
.has-warning .ant-time-picker-icon:after,
.has-warning .ant-picker-icon:after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: var(--sally-status-warning-text);
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: var(--sally-status-warning-border);
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: var(--sally-status-warning-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 191, 0, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: var(--sally-status-warning-border);
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: var(--sally-status-warning-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 191, 0, 0.2);
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: var(--sally-status-failure-text-on-white);
}
.has-error .ant-input,
.has-error .ant-input:hover {
  border-color: var(--sally-status-failure-border);
}
.has-error .ant-input:focus {
  border-color: var(--sally-status-failure-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 65, 52, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: var(--sally-status-failure-border);
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: var(--sally-status-failure-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 65, 52, 0.2);
}
.has-error .ant-input-prefix {
  color: var(--sally-status-failure-text-on-white);
}
.has-error .ant-input-group-addon {
  color: var(--sally-status-failure-text-on-white);
  border-color: var(--sally-status-failure-border);
  background-color: var(--sally-background-layer-1);
}
.has-error .has-feedback {
  color: var(--sally-status-failure-text-on-white);
}
.has-error.has-feedback:after {
  content: '\e62e';
  color: var(--sally-status-failure-text-on-white);
}
.has-error .ant-select-selection {
  border-color: var(--sally-status-failure-border);
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: var(--sally-status-failure-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 65, 52, 0.2);
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon:after,
.has-error .ant-time-picker-icon:after,
.has-error .ant-picker-icon:after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: var(--sally-status-failure-text-on-white);
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: var(--sally-status-failure-border);
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: var(--sally-status-failure-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 65, 52, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: var(--sally-status-failure-border);
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: var(--sally-status-failure-border);
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: var(--sally-status-failure-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 65, 52, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: var(--sally-status-failure-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 65, 52, 0.2);
}
.is-validating.has-feedback:after {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
  content: "\e64d";
  color: var(--sally-status-awaiting-system-text-on-white);
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 16px;
}
.ant-advanced-search-form .ant-input,
.ant-advanced-search-form .ant-input-group .ant-input,
.ant-advanced-search-form .ant-input-group .ant-input-group-addon {
  height: 28px;
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

body {
  .ant-form {
    .form-error {
      margin-bottom: 5px;
      padding-left: 20px;
      background-image: url('/src/images/icon-cross-red-20x20.png');
      background-position: -4px center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      line-height: 20px;
      font-size: 14px;
      color: var(--sally-status-failure-text-on-white);
    }
    .ant-row, .ant-subrow {
      margin-right: -10px;
      margin-left: -10px;

      > div {
        padding: 0 10px;

        > .ant-row {
          margin-right: 0;
          margin-left: 0;

          > div {
            padding: 0;
          }
        }
      }
      & + .ant-row {
        margin-top: 15px;
      }
    }
    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-control {
        line-height: inherit;

        .ant-form-extra {
          display: block;
          margin-top: 10px;
        }
        .ant-input-number {
          margin-top: 0;
        }
        .ant-switch, .ant-radio-group {
          margin-top: 9px;
          margin-bottom: 9px;
        }
        .ant-field-wrapper {
          position: relative;
          width: 100%;
          height: 38px;
          background-color: var(--sally-background-layer-1);

          > * {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background-color: transparent;

            &.ant-form-suffix {
              bottom: 0;
            }
          }
        }
        .ant-form-suffix, .ant-field-wrapper.ant-form-suffix {
          position: absolute;
          top: auto;
          right: 15px;
          bottom: 5px;
          left: auto;
          z-index: 1;
          height: 36px;
          line-height: 36px;
          color: var(--sally-form-text);
        }
      }
      &.ant-form-item-hide-error {
        .ant-form-item-control-wrapper {
          .ant-form-item-control.has-error {
            label {
              color: var(--sally-form-label);
            }
            .ant-input {
              border-color: var(--sally-input-border);
            }
            .ant-input-group-addon {
              border-color: var(--sally-form-border);
              color: var(--sally-input-text);
            }
            .ant-input-wrapper {
              .ant-input {
                border-color: var(--sally-input-border);
              }
              &:not(.ant-input-group):after {
                display: none;
              }
            }
            .ant-select {
              .ant-select-selection {
                border-color: var(--sally-form-border);
              }
            }
          }
        }
      }
    }
    .ant-form-extra,
    .ant-form-char-limit {
      color: var(--sally-input-placeholder-text);
    }
    .ant-form-char-limit {
      .ant-form-char-limit-count {
        float: right;
      }
      .ant-form-char-limit-warning {
        color: var(--sally-status-failure-text-on-white);
      }
    }
    .ant-btn[type="submit"] {
      display: block;
      width: 100%;

      &.ant-btn-secondary {
        position: relative;
        display: inline-block;
        margin-top: 20px;
        padding-right: 24px;
        padding-left: 24px;
      }
    }
    .ant-btn + .ant-btn {
      display: inline-block;
      margin-left: 16px;
    }
    .ant-form-warning {
      display: inline-block;
      padding: 2px 0 2px 35px;
      background-image: url('/src/images/icon-circle-info-orange-22x22.png');
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      font-weight: 500;
      color: var(--sally-status-warning-text-on-white);
    }
    .ant-form-warning p {
      color: var(--sally-status-warning-text-on-white);
    }
    .ant-form-warning-top {
      display: inline-block;
      padding: 35px 0 2px 2px;
      background-image: url('/src/images/icon-circle-info-orange-22x22.png');
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      font-weight: 500;
      color: var(--sally-status-warning-text-on-white);
    }
    .ant-form-warning-top p {
      color: var(--sally-status-warning-text-on-white);
    }
    .ant-form-error {
      display: inline-block;
      padding: 2px 0 2px 35px;
      background-image: url('/src/images/icon-circle-warning-red-22x22.png');
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      font-weight: 500;
      color: var(--sally-status-failure-text-on-white);
    }
    .ant-form-error p {
      color: var(--sally-status-failure-text-on-white);
    }
    &.show-detailed-errors {
      .has-error {
        .ant-form-explain {
          display: block;
        }
      }
    }
  }
}
