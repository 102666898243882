.leave-registration {
  padding-bottom: 50px;
}
.leave-registration .leave-registrations-header {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
.leave-registration > div + div .leave-registrations-header, .leave-registration > div > div + div .leave-registrations-header {
  margin-top: 30px;
}
.leave-registration .leave-registration-date {
  font-size: 14px;
  font-weight: 300;
  color: var(--sally-text);
}
.leave-registration .leave-registration-description {
  font-size: 17px;
  font-weight: 500;
  color: var(--sally-text);
}
.leave-registration .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}

body .ant-card.leave-balances .ant-card-body, body .ant-card.leave-balances:hover .ant-card-body {
  padding: 15px 20px;
}
body .ant-card.leave-balances .ant-row > div {
  padding: 0;
}
body .ant-card.leave-balances div.ant-row, body .ant-card.leave-balances div.ant-row + div.ant-row {
  margin: 0;
}
body .ant-card.leave-balances .leave-balances-total {
  font-weight: bold;
}
body .ant-card.leave-balances .leave-balances-days {
  text-align: right;
}
body .ant-card.leave-balances .leave-balances-group:not(:first-child) {
  margin-top: 10px;
}
body .ant-card.leave-balances .leave-balances-table-header {
  font-weight: bold;
}
body .ant-card.leave-balances .leave-balances-header {
  margin-top: 8px;
  font-weight: bold;
  text-align: center;
  font-size: 10pt;
}
body .ant-card.leave-balances .leave-balances-explanation {
  font-style: italic;
  font-size: 8pt;
}
body .ant-card.leave-balances .leave-balances-explanation p:first-child {
  margin-top: 5px;
}
body .ant-card.leave-balances .leave-balance-explanation-link {
  text-align: right;
}
body .ant-card.leave-balances .leave-balance-explanation-link a {
  text-decoration: underline;
}

.leave-calculation-explanation .disclaimer {
  font-style: italic;
}
.leave-calculation-explanation .button-container {
  text-align: right;
}

