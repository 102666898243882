.ant-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid var(--sally-button-text-border);
  white-space: nowrap;
  line-height: 1.15;
  height: var(--sally-input-m-height);
  padding: 8px 12px 8px 12px;
  font-size: 12px;
  border-radius: var(--sally-radius-10);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  color: var(--sally-button-text-text);
  background-color: var(--sally-button-text-background);
}
.ant-btn, .ant-btn .icon-fill {
  fill: var(--sally-button-text-text);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn, .ant-btn:active, .ant-btn:focus {
  outline: 0;
}
.ant-btn:hover {
  text-decoration: none;
}
.ant-btn.disabled, .ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *, .ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn.ant-btn-xxl {
  padding: 8px 20px 8px 20px;
  font-size: 14px;
  line-height: 18px;
  height: var(--sally-input-xxl-height);
  border-radius: var(--sally-radius-16);
}
.ant-btn.ant-btn-xl {
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  line-height: 18px;
  height: var(--sally-input-xl-height);
  border-radius: var(--sally-radius-12);
}
.ant-btn.ant-btn-l {
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 18px;
  height: var(--sally-input-l-height);
  border-radius: var(--sally-radius-12);
}
.ant-btn > a:only-child {
  color: inherit;
}
.ant-btn:hover {
  background-color: var(--sally-button-text-hover-background);
}
.ant-btn:hover > a:only-child {
  color: inherit;
}
.ant-btn:focus {
  border-color: var(--sally-button-text-focus-border);
}
.ant-btn:focus > a:only-child {
  color: inherit;
}
.ant-btn:active, .ant-btn.active {
  color: var(--sally-button-text-active-text);
  background-color: var(--sally-button-text-active-background);
}
.ant-btn:active > a:only-child, .ant-btn.active > a:only-child {
  color: inherit;
}
.ant-btn:active .icon-fill, .ant-btn.active .icon-fill {
  fill: var(--sally-button-text-active-text);
}
.ant-btn.disabled, .ant-btn[disabled], .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn.disabled.active, .ant-btn[disabled].active {
  color: var(--sally-button-text-disabled-text);
  background-color: var(--sally-button-text-disabled-background);
  border-color: var(--sally-button-text-border);
}
.ant-btn.disabled > a:only-child, .ant-btn[disabled] > a:only-child, .ant-btn.disabled:hover > a:only-child, .ant-btn[disabled]:hover > a:only-child, .ant-btn.disabled:focus > a:only-child, .ant-btn[disabled]:focus > a:only-child, .ant-btn.disabled:active > a:only-child, .ant-btn[disabled]:active > a:only-child, .ant-btn.disabled.active > a:only-child, .ant-btn[disabled].active > a:only-child {
  color: inherit;
}
.ant-btn.disabled .icon-fill, .ant-btn[disabled] .icon-fill, .ant-btn.disabled:hover .icon-fill, .ant-btn[disabled]:hover .icon-fill, .ant-btn.disabled:focus .icon-fill, .ant-btn[disabled]:focus .icon-fill, .ant-btn.disabled:active .icon-fill, .ant-btn[disabled]:active .icon-fill, .ant-btn.disabled.active .icon-fill, .ant-btn[disabled].active .icon-fill {
  fill: var(--sally-button-text-disabled-text);
}
.ant-btn > i, .ant-btn > span {
  pointer-events: none;
}
.ant-btn.ant-btn-loading {
  color: var(--sally-button-text-loading-text);
}
.ant-btn.ant-btn-danger {
  color: var(--sally-button-text-danger-text);
  background-color: var(--sally-button-text-danger-background);
}
.ant-btn.ant-btn-danger .icon-fill {
  fill: var(--sally-button-text-danger-text);
}
.ant-btn.ant-btn-danger:hover {
  color: var(--sally-button-text-danger-hover-text);
  background-color: var(--sally-button-text-danger-hover-background);
}
.ant-btn.ant-btn-danger:hover .icon-fill {
  fill: var(--sally-button-text-danger-hover-text);
}
.ant-btn.ant-btn-danger:active, .ant-btn.ant-btn-danger.active {
  background-color: var(--sally-button-text-danger-active-background);
}
.ant-btn.ant-btn-danger.ant-btn-loading {
  color: var(--sally-button-text-danger-text);
}
.ant-btn.ant-btn-danger.ant-btn-loading .icon-fill {
  fill: var(--sally-button-text-danger-text);
}
.ant-btn.ant-btn-secondary {
  color: var(--sally-button-secondary-text);
  background-color: var(--sally-button-secondary-background);
  border-color: var(--sally-button-secondary-border);
  box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);
}
.ant-btn.ant-btn-secondary .icon-fill {
  fill: var(--sally-button-secondary-text);
}
.ant-btn.ant-btn-secondary:hover {
  background-color: var(--sally-button-secondary-hover-background);
}
.ant-btn.ant-btn-secondary:focus {
  border-color: var(--sally-button-secondary-focus-border);
  background-color: var(--sally-button-secondary-focus-border);
  color: var(--sally-button-secondary-text);
}
.ant-btn.ant-btn-secondary:active, .ant-btn.ant-btn-secondary.active {
  background-color: var(--sally-button-secondary-active-background);
}
.ant-btn.ant-btn-secondary.ant-btn-loading {
  color: var(--sally-button-secondary-loading-text);
  background-color: var(--sally-button-secondary-loading-background);
}
.ant-btn.ant-btn-secondary.ant-btn-loading .icon-fill {
  fill: var(--sally-button-secondary-loading-text);
}
.ant-btn.ant-btn-secondary.disabled, .ant-btn.ant-btn-secondary[disabled], .ant-btn.ant-btn-secondary.disabled:hover, .ant-btn.ant-btn-secondary[disabled]:hover, .ant-btn.ant-btn-secondary.disabled:focus, .ant-btn.ant-btn-secondary[disabled]:focus, .ant-btn.ant-btn-secondary.disabled:active, .ant-btn.ant-btn-secondary[disabled]:active, .ant-btn.ant-btn-secondary.disabled.active, .ant-btn.ant-btn-secondary[disabled].active {
  color: var(--sally-button-secondary-disabled-text);
  background-color: var(--sally-button-secondary-background);
  border-color: var(--sally-button-secondary-border);
}
.ant-btn.ant-btn-secondary.disabled .icon-fill, .ant-btn.ant-btn-secondary[disabled] .icon-fill, .ant-btn.ant-btn-secondary.disabled:hover .icon-fill, .ant-btn.ant-btn-secondary[disabled]:hover .icon-fill, .ant-btn.ant-btn-secondary.disabled:focus .icon-fill, .ant-btn.ant-btn-secondary[disabled]:focus .icon-fill, .ant-btn.ant-btn-secondary.disabled:active .icon-fill, .ant-btn.ant-btn-secondary[disabled]:active .icon-fill, .ant-btn.ant-btn-secondary.disabled.active .icon-fill, .ant-btn.ant-btn-secondary[disabled].active .icon-fill {
  fill: var(--sally-button-secondary-disabled-text);
}
.ant-btn.ant-btn-secondary.ant-btn-danger {
  color: var(--sally-button-secondary-danger-text);
  background-color: var(--sally-button-secondary-danger-background);
  border-color: var(--sally-button-secondary-danger-border);
}
.ant-btn.ant-btn-secondary.ant-btn-danger .icon-fill {
  fill: var(--sally-button-secondary-danger-text);
}
.ant-btn.ant-btn-secondary.ant-btn-danger:hover {
  color: var(--sally-button-secondary-danger-hover-text);
  background-color: var(--sally-button-secondary-danger-hover-background);
}
.ant-btn.ant-btn-secondary.ant-btn-danger:hover .icon-fill {
  fill: var(--sally-button-secondary-danger-hover-text);
}
.ant-btn.ant-btn-secondary.ant-btn-danger:active, .ant-btn.ant-btn-secondary.ant-btn-danger.active {
  background-color: var(--sally-button-secondary-danger-active-background);
}
.ant-btn.ant-btn-secondary.ant-btn-danger.ant-btn-loading {
  background-color: var(--sally-button-secondary-danger-loading-background);
}
.ant-btn.ant-btn-tertiary {
  color: var(--sally-button-tertiary-text);
  background-color: var(--sally-button-tertiary-background);
  border-color: var(--sally-button-tertiary-border);
  box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);
}
.ant-btn.ant-btn-tertiary .icon-fill {
  fill: var(--sally-button-tertiary-text);
}
.ant-btn.ant-btn-tertiary:hover {
  background-color: var(--sally-button-tertiary-hover-background);
}
.ant-btn.ant-btn-tertiary:focus {
  border-color: var(--sally-button-tertiary-focus-border);
}
.ant-btn.ant-btn-tertiary:active, .ant-btn.ant-btn-tertiary.active {
  background-color: var(--sally-button-tertiary-active-background);
}
.ant-btn.ant-btn-tertiary.ant-btn-loading {
  color: var(--sally-button-tertiary-loading-text);
  background-color: var(--sally-button-tertiary-loading-background);
}
.ant-btn.ant-btn-tertiary.ant-btn-loading .icon-fill {
  fill: var(--sally-button-tertiary-loading-text);
}
.ant-btn.ant-btn-tertiary.disabled, .ant-btn.ant-btn-tertiary[disabled], .ant-btn.ant-btn-tertiary.disabled:hover, .ant-btn.ant-btn-tertiary[disabled]:hover, .ant-btn.ant-btn-tertiary.disabled:focus, .ant-btn.ant-btn-tertiary[disabled]:focus, .ant-btn.ant-btn-tertiary.disabled:active, .ant-btn.ant-btn-tertiary[disabled]:active, .ant-btn.ant-btn-tertiary.disabled.active, .ant-btn.ant-btn-tertiary[disabled].active {
  color: var(--sally-button-tertiary-disabled-text);
  background-color: var(--sally-button-tertiary-background);
  border-color: var(--sally-button-tertiary-border);
}
.ant-btn.ant-btn-tertiary.disabled .icon-fill, .ant-btn.ant-btn-tertiary[disabled] .icon-fill, .ant-btn.ant-btn-tertiary.disabled:hover .icon-fill, .ant-btn.ant-btn-tertiary[disabled]:hover .icon-fill, .ant-btn.ant-btn-tertiary.disabled:focus .icon-fill, .ant-btn.ant-btn-tertiary[disabled]:focus .icon-fill, .ant-btn.ant-btn-tertiary.disabled:active .icon-fill, .ant-btn.ant-btn-tertiary[disabled]:active .icon-fill, .ant-btn.ant-btn-tertiary.disabled.active .icon-fill, .ant-btn.ant-btn-tertiary[disabled].active .icon-fill {
  fill: var(--sally-button-tertiary-disabled-text);
}
.ant-btn.ant-btn-tertiary.ant-btn-danger {
  color: var(--sally-button-tertiary-danger-text);
  background-color: var(--sally-button-tertiary-danger-background);
  border-color: var(--sally-button-tertiary-danger-border);
}
.ant-btn.ant-btn-tertiary.ant-btn-danger .icon-fill {
  fill: var(--sally-button-tertiary-danger-text);
}
.ant-btn.ant-btn-tertiary.ant-btn-danger:hover {
  color: var(--sally-button-tertiary-danger-hover-text);
  background-color: var(--sally-button-tertiary-danger-hover-background);
}
.ant-btn.ant-btn-tertiary.ant-btn-danger:hover .icon-fill {
  fill: var(--sally-button-tertiary-danger-hover-text);
}
.ant-btn.ant-btn-tertiary.ant-btn-danger:active, .ant-btn.ant-btn-tertiary.ant-btn-danger.active {
  background-color: var(--sally-button-tertiary-danger-active-background);
}
.ant-btn.ant-btn-tertiary.ant-btn-danger.ant-btn-loading {
  background-color: var(--sally-button-tertiary-danger-loading-background);
}
.ant-btn.ant-btn-primary {
  color: var(--sally-button-primary-text);
  background-color: var(--sally-button-primary-background);
  border-color: var(--sally-button-primary-border);
  box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);
}
.ant-btn.ant-btn-primary .icon-fill {
  fill: var(--sally-button-primary-text);
}
.ant-btn.ant-btn-primary:hover {
  color: var(--sally-button-primary-hover-text);
  background-color: var(--sally-button-primary-hover-background);
}
.ant-btn.ant-btn-primary:hover .icon-fill {
  fill: var(--sally-button-primary-hover-text);
}
.ant-btn.ant-btn-primary:focus {
  border-color: var(--sally-button-primary-focus-border);
}
.ant-btn.ant-btn-primary:active, .ant-btn.ant-btn-primary.active {
  color: var(--sally-button-primary-active-text);
  background-color: var(--sally-button-primary-active-background);
}
.ant-btn.ant-btn-primary:active .icon-fill, .ant-btn.ant-btn-primary.active .icon-fill {
  fill: var(--sally-button-primary-active-text);
}
.ant-btn.ant-btn-primary.ant-btn-loading {
  color: var(--sally-button-primary-loading-text);
  background-color: var(--sally-button-primary-loading-background);
}
.ant-btn.ant-btn-primary.ant-btn-loading .icon-fill {
  fill: var(--sally-button-primary-loading-text);
}
.ant-btn.ant-btn-primary.disabled, .ant-btn.ant-btn-primary[disabled], .ant-btn.ant-btn-primary.disabled:hover, .ant-btn.ant-btn-primary[disabled]:hover, .ant-btn.ant-btn-primary.disabled:focus, .ant-btn.ant-btn-primary[disabled]:focus, .ant-btn.ant-btn-primary.disabled:active, .ant-btn.ant-btn-primary[disabled]:active, .ant-btn.ant-btn-primary.disabled.active, .ant-btn.ant-btn-primary[disabled].active {
  color: var(--sally-button-primary-disabled-text);
  background-color: var(--sally-button-primary-background);
  border-color: var(--sally-button-primary-border);
}
.ant-btn.ant-btn-primary.disabled .icon-fill, .ant-btn.ant-btn-primary[disabled] .icon-fill, .ant-btn.ant-btn-primary.disabled:hover .icon-fill, .ant-btn.ant-btn-primary[disabled]:hover .icon-fill, .ant-btn.ant-btn-primary.disabled:focus .icon-fill, .ant-btn.ant-btn-primary[disabled]:focus .icon-fill, .ant-btn.ant-btn-primary.disabled:active .icon-fill, .ant-btn.ant-btn-primary[disabled]:active .icon-fill, .ant-btn.ant-btn-primary.disabled.active .icon-fill, .ant-btn.ant-btn-primary[disabled].active .icon-fill {
  fill: var(--sally-button-primary-disabled-text);
}
.ant-btn.ant-btn-primary.ant-btn-danger {
  color: var(--sally-button-primary-danger-text);
  background-color: var(--sally-button-primary-danger-background);
}
.ant-btn.ant-btn-primary.ant-btn-danger .icon-fill {
  fill: var(--sally-button-primary-danger-text);
}
.ant-btn.ant-btn-primary.ant-btn-danger:hover {
  background-color: var(--sally-button-primary-danger-hover-background);
}
.ant-btn.ant-btn-primary.ant-btn-danger:active, .ant-btn.ant-btn-primary.ant-btn-danger.active {
  background-color: var(--sally-button-primary-danger-active-background);
}
.ant-btn.ant-btn-primary.ant-btn-danger.ant-btn-loading {
  color: var(--sally-button-primary-loading-text);
  background-color: var(--sally-button-primary-danger-loading-background);
}
.ant-btn.ant-btn-primary.ant-btn-danger.ant-btn-loading .icon-fill {
  fill: var(--sally-button-primary-loading-text);
}
.ant-btn.icon-form-button {
  margin-top: var(--sally-form-adjust-height);
  width: 100%;
  padding-left: 13px;
}
.ant-btn.icon-form-button .icon {
  float: none;
  margin-right: 0;
}
.ant-btn:not(.ant-btn-icon) .ant-btn-prefix-icon {
  float: left;
  margin-right: 7px;
}
.ant-btn:not(.ant-btn-icon) .ant-btn-suffix-icon {
  float: right;
  margin-left: 7px;
}

