.documents {
    padding-top: 30px;

    .btn-group {
        margin-top: -30px;
        margin-bottom: 20px;
        text-align: center;
    }
    a .ant-card.ant-card-bordered, .ant-card.ant-card-bordered {
        &, &:hover {
            .ant-card-body {
                position: relative;
                padding: 15px 20px;
            }
        }
        & + a, & + .ant-card.ant-card-bordered {
            margin-top: 20px;
        }
    }
    a .ant-card.ant-card-bordered .ant-card-body:after, a.view-document:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 25px;
        visibility: visible;
        display: block;
        width: 7px;
        height: 13px;
        margin-top: -6px;
        background-image: url('/src/images/icon-arrow-right-grey-7x13.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 7px 13px;
    }
    .registration-row-delete {
        position: absolute;
        top: 50%;
        right: 50px;
        visibility: visible;
        display: block;
        width: 61px;
        height: 61px;
        margin-top: -30px;
        background-image: url('/src/images/icon-cross-grey-11x11.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 11px 11px;
        cursor: pointer;
    }
    .document-amount {
        font-size: 17px;
        font-weight: 500;
        color: var(--sally-black);
    }
    .document-period {
        font-size: 14px;
        font-weight: 500;
        color: #424d5e;
    }
    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: #778da0;
    }
    .documents-add {
         position: fixed;
         right: 0;
         bottom: 62px;
         left: 0;
         height: 50px;
         background-color: #ffa800;
         line-height: 50px;
         text-align: center;
         font-size: 17px;
         font-weight: 600;
         color: #fff;
     }
    &.platform-ios {
        .documents-add {
            bottom: 92px;
        }
    }
}
