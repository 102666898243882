$fontSize: 24px;
$sliderHeight: 80px;
$sliderPadding: 7px;

@mixin track($color: red) {
    &::-webkit-slider-runnable-track {
        @content;
        border-radius: 1000px;
        background-color: $color;
        border: 0;
    }
    &::-moz-range-track {
        @content;
        border-radius: 1000px;
        background-color: $color;
        border: 0;
    }
    &::-moz-focus-outer {
        border: 0;
    }
    &::-ms-track {
        border: 0;
        @content;
        border-radius: 1000px;
        background-color: $color;
        color: transparent;
    }
    &::-ms-fill-lower, &::-ms-fill-upper {
        background-color: transparent;
    }
    &::-ms-tooltip {
        display: none;
    }
}

@mixin thumb() {
    &::-webkit-slider-thumb {
        appearance: none;
        border: 0;
        @content;
    }
    &::-moz-range-thumb {
        border: 0;
        @content;
    }
    &::-ms-thumb {
        @content;
    }
}

.slider {
    width: 100%;
    appearance: none;
    background: transparent;
    height: $sliderHeight;

    &:focus { // accessibility
      outline: none;
    }
    @include thumb() { // thumb styles
        display: block;
        width: $sliderHeight - $sliderPadding * 2;
        height: $sliderHeight - $sliderPadding * 2;
        margin: $sliderPadding;
        border-radius: 50%;
        background-color: var(--sally-button-primary-text);
        // background-image: url('/src/images/icon-arrow-right-white-30x30.png');
        // background-position: center center;
        // background-repeat: no-repeat;
        background-size: 30px 30px;
        transform-origin: 50% 50%;
        transform: scale(1);
        transition: transform ease-out 100ms;
        cursor: -webkit-grab;
        cursor: -moz-grab;
    }
    @include track(var(--sally-button-primary-background)) { // track styles
        box-sizing: content-box;
        height: $sliderHeight;
    }
    &:active { // handle active states
        @include thumb() {
            cursor: -webkit-grabbing;
            cursor: -moz-grabbing;
        }
    }
    &.disabled {
        cursor: not-allowed;
        @include thumb() {
            background-color: var(--sally-button-primary-loading-text);
            cursor: not-allowed;
        }
        @include track(var( --sally-button-primary-disabled-text)) {
            box-sizing: content-box;
            height: $sliderHeight;
        }
    }
}
