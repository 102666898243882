body .employees-work-hours-tab {
  .work-week-header {
    h2 {
      text-align: center;
    }
  }
  .work-hours-week-form {
    .work-hours-week-form-hours-week {
      margin-bottom: 15px;
      font-size: 12pt;
      line-height: 18pt;
    }
    .ant-row .text-cell {
      padding-top: 8px;
      text-align: right;
    }
  }
  .work-hours-week-nav {
    .ant-btn {
      padding: 4px 8px;
    }
  }
  .register-time-registration {
    margin-top: 15px;
    width: 100%;
  }
  .work-hours-weekday {
    text-align: right;
  }
  .ant-form .ant-row > div {
    padding: 0 1px;

    &.work-hours-weekday {
      padding-right: 4px;
    }
  }
  .ant-card {
    .ant-card-body {
      .btn-group {
        text-align: center;
        margin-bottom: 10px;
      }

      .work-week-summary {
        text-align: center;
        font-size: 13pt;
        color: var(--sally-paragraph-text);

        span {
          font-weight: bold;
        }
      }

      .work-week-template-button {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
      }
    }
  }
  .work-week-template {
    padding: 5px 10px;
  }
  .work-hours-week-form {
    .ant-btn {

      &.ant-btn-primary {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}