.slider {
  width: 100%;
  appearance: none;
  background: transparent;
  height: 80px;
}
.slider:focus {
  outline: none;
}
.slider::-webkit-slider-thumb {
  appearance: none;
  border: 0;
  display: block;
  width: 66px;
  height: 66px;
  margin: 7px;
  border-radius: 50%;
  background-color: var(--sally-button-primary-text);
  background-size: 30px 30px;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.slider::-moz-range-thumb {
  border: 0;
  display: block;
  width: 66px;
  height: 66px;
  margin: 7px;
  border-radius: 50%;
  background-color: var(--sally-button-primary-text);
  background-size: 30px 30px;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.slider::-ms-thumb {
  display: block;
  width: 66px;
  height: 66px;
  margin: 7px;
  border-radius: 50%;
  background-color: var(--sally-button-primary-text);
  background-size: 30px 30px;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.slider::-webkit-slider-runnable-track {
  box-sizing: content-box;
  height: 80px;
  border-radius: 1000px;
  background-color: var(--sally-button-primary-background);
  border: 0;
}
.slider::-moz-range-track {
  box-sizing: content-box;
  height: 80px;
  border-radius: 1000px;
  background-color: var(--sally-button-primary-background);
  border: 0;
}
.slider::-moz-focus-outer {
  border: 0;
}
.slider::-ms-track {
  border: 0;
  box-sizing: content-box;
  height: 80px;
  border-radius: 1000px;
  background-color: var(--sally-button-primary-background);
  color: transparent;
}
.slider::-ms-fill-lower, .slider::-ms-fill-upper {
  background-color: transparent;
}
.slider::-ms-tooltip {
  display: none;
}
.slider:active::-webkit-slider-thumb {
  appearance: none;
  border: 0;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.slider:active::-moz-range-thumb {
  border: 0;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.slider:active::-ms-thumb {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.slider.disabled {
  cursor: not-allowed;
}
.slider.disabled::-webkit-slider-thumb {
  appearance: none;
  border: 0;
  background-color: var(--sally-button-primary-loading-text);
  cursor: not-allowed;
}
.slider.disabled::-moz-range-thumb {
  border: 0;
  background-color: var(--sally-button-primary-loading-text);
  cursor: not-allowed;
}
.slider.disabled::-ms-thumb {
  background-color: var(--sally-button-primary-loading-text);
  cursor: not-allowed;
}
.slider.disabled::-webkit-slider-runnable-track {
  box-sizing: content-box;
  height: 80px;
  border-radius: 1000px;
  background-color: var(--sally-button-primary-disabled-text);
  border: 0;
}
.slider.disabled::-moz-range-track {
  box-sizing: content-box;
  height: 80px;
  border-radius: 1000px;
  background-color: var(--sally-button-primary-disabled-text);
  border: 0;
}
.slider.disabled::-moz-focus-outer {
  border: 0;
}
.slider.disabled::-ms-track {
  border: 0;
  box-sizing: content-box;
  height: 80px;
  border-radius: 1000px;
  background-color: var(--sally-button-primary-disabled-text);
  color: transparent;
}
.slider.disabled::-ms-fill-lower, .slider.disabled::-ms-fill-upper {
  background-color: transparent;
}
.slider.disabled::-ms-tooltip {
  display: none;
}

