.documents {
  padding-top: 30px;
}
.documents .btn-group {
  margin-top: -30px;
  margin-bottom: 20px;
  text-align: center;
}
.documents a .ant-card.ant-card-bordered .ant-card-body, .documents a .ant-card.ant-card-bordered:hover .ant-card-body, .documents .ant-card.ant-card-bordered .ant-card-body, .documents .ant-card.ant-card-bordered:hover .ant-card-body {
  position: relative;
  padding: 15px 20px;
}
.documents a .ant-card.ant-card-bordered + a, .documents a .ant-card.ant-card-bordered + .ant-card.ant-card-bordered, .documents .ant-card.ant-card-bordered + a, .documents .ant-card.ant-card-bordered + .ant-card.ant-card-bordered {
  margin-top: 20px;
}
.documents a .ant-card.ant-card-bordered .ant-card-body:after, .documents a.view-document:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  visibility: visible;
  display: block;
  width: 7px;
  height: 13px;
  margin-top: -6px;
  background-image: url("/src/images/icon-arrow-right-grey-7x13.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 7px 13px;
}
.documents .registration-row-delete {
  position: absolute;
  top: 50%;
  right: 50px;
  visibility: visible;
  display: block;
  width: 61px;
  height: 61px;
  margin-top: -30px;
  background-image: url("/src/images/icon-cross-grey-11x11.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  cursor: pointer;
}
.documents .document-amount {
  font-size: 17px;
  font-weight: 500;
  color: var(--sally-black);
}
.documents .document-period {
  font-size: 14px;
  font-weight: 500;
  color: #424d5e;
}
.documents .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: #778da0;
}
.documents .documents-add {
  position: fixed;
  right: 0;
  bottom: 62px;
  left: 0;
  height: 50px;
  background-color: #ffa800;
  line-height: 50px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
.documents.platform-ios .documents-add {
  bottom: 92px;
}

