.leave-registration {
    padding-bottom: 50px;

    .leave-registrations-header {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }
    & > div, & > div > div {
        & + div {
            .leave-registrations-header {
                margin-top: 30px;
            }
        }
    }
    .leave-registration-date {
        font-size: 14px;
        font-weight: 300; 
        color: var(--sally-text);
    }
    .leave-registration-description {
        font-size: 17px;
        font-weight: 500;
        color: var(--sally-text);
    }
    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: var(--sally-paragraph-text);
    }
    // .leave-registrations-add {
    //     position: fixed;
    //     right: 0;
    //     bottom: 62px;
    //     left: 0;
    //     height: 50px;
    //     background-color: var(--sally-button-primary-background);
    //     line-height: 50px;
    //     text-align: center;
    //     font-size: 17px;
    //     font-weight: 600;
    //     color: var(--sally-button-primary-text);
    // }
    // &.platform-ios {
    //     .leave-registrations-add {
    //         bottom: 92px;
    //     }
    // }
}
body .ant-card.leave-balances {
    .ant-card-body, &:hover .ant-card-body {
        padding: 15px 20px;
    }
    .ant-row > div {
        padding: 0;
    }
    div.ant-row, div.ant-row + div.ant-row {
        margin: 0;
    }
    .leave-balances-total {
        font-weight: bold;
    }
    .leave-balances-days {
        text-align: right;
    }
    .leave-balances-group:not(:first-child) {
        margin-top: 10px;
    }
    .leave-balances-table-header {
        font-weight: bold;
    }
    .leave-balances-header {
        margin-top: 8px;
        font-weight: bold;
        text-align: center;
        font-size: 10pt;
    }
    .leave-balances-explanation {
        font-style: italic;
        font-size: 8pt;

        p:first-child {
            margin-top: 5px;
        }
    }
    .leave-balance-explanation-link {
        text-align: right;

        a {
            text-decoration: underline;
        }
    }
}
.leave-calculation-explanation {
    .disclaimer {
        font-style: italic;
    }
    .button-container {
        text-align: right;
    }
}