@mixin amount_thumb() {
    &::-webkit-slider-thumb {
        appearance: none;
        margin-top: -7px;
        border: 0;
        @content;
    }
    &::-moz-range-thumb {
        border: 0;
        @content;
    }
    &::-ms-thumb {
        @content;
    }
}
@mixin amount_track($radius: 0) {
    &::-webkit-slider-runnable-track {
        @content;
        border-radius: $radius;
        background-color: var(--sally-background);
        border: 0;
    }
    &::-moz-range-track {
        @content;
        border-radius: $radius;
        background-color: var(--sally-code-border);
        border: 0;
    }
    &::-moz-focus-outer {
        border: 0;
    }
    &::-ms-track {
        border: 0;
        @content;
        border-radius: $radius;
        background-color: var(--sally-code-border);
        color: transparent;
    }
    &::-ms-fill-lower, &::-ms-fill-upper {
        background-color: transparent;
    }
    &::-ms-tooltip {
        display: none;
    }
}

.swipe {
    padding-top: 30px;
    padding-bottom: 50px;

    .swipe-introduction {
        .swipe-history {
            float: right;
            margin-top: -30px;
            font-size: 14px;
            font-weight: 500;
            color: var(--sally-button-primary-background);
        }
        .swipe-amount-container {
            padding-top: 30px;

            .swipe-amount-max, .swipe-amount-min {
                font-size: 24px;
            }
            .swipe-amount-min {
                padding-bottom: 15px;
            }
            .swipe-amount-max {
                cursor: pointer;
                float: right;
            }
            .select-amount-slider {
                appearance: none;
                width: 100%;
                height: 1rem;
                background: transparent;
    
                &:active { // handle active states
                    @include amount_thumb() {
                        transform: scale(1.1);
                        cursor: -webkit-grabbing;
                        cursor: -moz-grabbing;
                    }
                }
                &:focus { // accessibility
                    outline: none;
                }
                @include amount_thumb() { // thumb styles
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background: var(--sally-header-submenu-background);
                    transform-origin: 50% 50%;
                    transform: scale(1);
                    transition: transform ease-out 100ms;
                    cursor: -webkit-grab;
                    cursor: -moz-grab;
                }
                @include amount_track(3rem) { // track styles
                    padding: .25rem;
                    height: 0.1rem;
                    box-sizing: content-box;
                }
            }
            .swipe-amount-description {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
    .swipe-slider-container {
        position: fixed;
        bottom: 62px;
        width: 90%;
        max-width: 768px;
        margin-bottom: 20px;

        .swipe-above-limit {
            padding-bottom: 10px;
            color: var(--sally-input-error-border);
        }
        .swipe-fee-note {
            font-size: 12pt;
        }
        .slider-container {
            height: 80px;
            padding-bottom: 48px;
            font-size: 24px;
            text-align: center;
            color: var(--sally-background);
        
            .slider-text {
                position: relative;
                top: -56px;
                padding-left: 30px;
            }
        }
        p {
            margin-top: 5px;
            text-align: center;
        }
    }
    &.platform-ios {
        .swipe-slider-container {
            bottom: 92px;
        }
    }
}
.swipes {
    padding-top: 30px;

    .swipe-header {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }
    & > div, & > div > div {
        & + div {
            .swipe-header {
                margin-top: 30px;
            }
        }
    }
    .swipe-date {
        font-size: 14px;
        font-weight: 300;
        color: var(--sally-paragraph-text);
    }
    .swipe-description {
        font-size: 17px;
        font-weight: 500;
        color: var(--sally-text);
    }
    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: var(--sally-paragraph-text);
    }
    .swipes-start {
        position: fixed;
        right: 0;
        bottom: 62px;
        left: 0;
        height: 50px;
        background-color: var(--sally-button-primary-text);
        line-height: 50px;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        color: var(--sally-button-primary-text);
    }
    &.platform-ios {
        .swipes-start {
            bottom: 92px;
        }
    }
}
.swipe-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--sally-button-primary-background);
    color: var(--sally-button-primary-text);
    text-align: center;
    padding: 15px;

    .swipe-overlay-title {
        font-size: 25pt;
        font-weight: bold;
        line-height: 30pt;
    }
    .swipe-overlay-text {
        font-size: 12pt;
        color: var(--sally-button-secondary-danger-hover-text);
    }
    .swipe-overlay-button, .swipe-overlay-button:hover {
        margin-top: 40px;
        background-color: var(--sally-button-primary-text);
        border-color: var(--sally-button-primary-text);
        font-weight: bold;
        font-size: 15pt;
        letter-spacing: 0.25pt;
        color: var(--sally-button-primary-background);
        width: 100%;
    }
    .swipe-overlay-button:hover a {
        text-decoration: none;
    }
}
