.preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);

    .loading-overlay {
        background-color: transparent;
    }
    .preview-container {
        position: absolute;
        top: 50px;
        top: calc(50px + env(safe-area-inset-top));
        right: 0;
        bottom: 50px;
        left: 0;
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
        z-index: 3;
        width: 100%;
        height: calc(100% - 100px);
        height: calc(100% - 100px - env(safe-area-inset-top));

        iframe {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 0;
        }
    }
    .preview-header {
        position: absolute;
        top: 0;
        top: env(safe-area-inset-top);
        right: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 50px;

        .h2 {
            line-height: 50px;
            text-align: center;
            font-weight: 500;
            color: var(--sally-header-text);
        }
    }
    .preview-footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        display: flex;
        width: 100%;
        height: 50px;

        & > a {
            display: block;
            width: 50%;
            height: 100%;
            line-height: 50px;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            color: var(--sally-alert-text);
            cursor: pointer;

            &:before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: 0 10px -4px 0;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 20px 20px;
            }
            &.preview-download:before {
                background-image: url('/src/images/icon-download-white-20x20.png');
            }
            &.preview-close:before {
                background-image: url('/src/images/icon-cross-white-20x20.png');
            }
        }
    }
}