.login > p {
  margin: 5px 0 20px 0;
  width: 280px;
  max-width: 100%;
  line-height: 22px;
  font-size: 14px;
  color: var(--sally-input-placeholder-text);
}
.login .ant-btn[type=submit] {
  margin-top: 15px;
}
.login .ant-checkbox {
  margin-right: 10px;
}

