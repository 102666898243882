body .dashboard .dashboard-controls {
  text-align: center;
  margin-bottom: 20px;
}
body .dashboard .dashboard-controls .dashboard-choice-switcher-toggle {
  margin-left: 25px;
}
body .dashboard .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--sally-grey);
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered {
  max-height: 50vh;
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered .dashboard-choice-switcher-toggle {
  padding: 2px 8px;
  position: absolute;
  top: 10px;
  right: 15px;
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered .dashboard-choice-register-button, body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered .dashboard-choice-page-button {
  width: 100%;
  margin-top: 15px;
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body {
  padding-right: 24px;
  padding-left: 24px;
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body .card-default {
  padding-right: 6px;
  padding-left: 6px;
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body .card-default .h3 {
  margin: -7px 0 0 0;
  font-size: 17px;
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body .card-default p {
  margin: 0 0 20px 0;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body .card-default .stat-large {
  display: inline-block;
  line-height: 40px;
  font-size: 40px;
  font-weight: 300;
  color: var(--sally-text);
}
@media only screen and (max-height: 600px) {
  body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body .card-default p {
    margin: 0;
  }
}
body .dashboard .dashboard-choice-card.ant-card.ant-card-bordered > .ant-card-body .card-default .stat-small {
  margin-top: 5px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: var(--sally-paragraph-text);
}
body .dashboard .card-work-hours .ant-form .work-hours-day-form > .ant-row > div {
  padding: 0 1px;
}
body .dashboard .card-work-hours .ant-form .work-hours-day-form > .ant-row > div:first-child {
  padding-left: 10px;
}
body .dashboard .card-work-hours .ant-form .work-hours-day-form > .ant-row > div:last-child {
  padding-right: 10px;
}
body .dashboard .swipe-link .ant-btn {
  width: 100%;
  margin-top: 15px;
  font-size: 20pt;
  white-space: inherit;
  height: 60px;
  text-align: center;
  line-height: 20pt;
}
body .dashboard .stat-pay-toggle {
  display: none;
}
@media only screen and (min-aspect-ratio: 1/1) {
  body .dashboard .card-default:not(.force-show-pay) .stat-pay {
    display: none;
  }
  body .dashboard .stat-pay-toggle {
    display: block;
    float: right;
  }
}

