.request-password {
    > div > p, > p {
        margin: 5px 0 20px 0;
        width: 280px;
        max-width: 100%;
        line-height: 22px;
        font-size: 14px;
        color: var(--sally-paragraph-text);
    }
    .ant-btn[type="submit"] {
        margin-top: 15px;
    }
}
