/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-input-search-icon {
  cursor: pointer;
  transition: all .3s;
  font-size: 14px;
}
.ant-input-search-icon:hover {
  color: var(--sally-blue);
}
.ant-search-input-wrapper {
  display: inline-block;
  vertical-align: middle;
}
.ant-search-input.ant-input-group .ant-input:first-child,
.ant-search-input.ant-input-group .ant-select:first-child {
  border-radius: 4px;
  position: absolute;
  top: -1px;
  width: 100%;
}
.ant-search-input.ant-input-group .ant-input:first-child {
  padding-right: 36px;
}
.ant-search-input .ant-search-btn {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-radius: 0 3px 3px 0;
  left: -1px;
  position: relative;
  border-width: 0 0 0 1px;
  z-index: 2;
  padding-left: 8px;
  padding-right: 8px;
}
.ant-search-input .ant-search-btn > a:only-child {
  color: currentColor;
}
.ant-search-input .ant-search-btn > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input .ant-search-btn:hover,
.ant-search-input .ant-search-btn:focus {
  color: var(--sally-blue);
  background-color: #fff;
  border-color: var(--sally-input-focus-border);
}
.ant-search-input .ant-search-btn:hover > a:only-child,
.ant-search-input .ant-search-btn:focus > a:only-child {
  color: currentColor;
}
.ant-search-input .ant-search-btn:hover > a:only-child:after,
.ant-search-input .ant-search-btn:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input .ant-search-btn:active,
.ant-search-input .ant-search-btn.active {
  color: var(--sally-blue-shaded);
  background-color: #fff;
  border-color: var(--sally-input-focus-border);
}
.ant-search-input .ant-search-btn:active > a:only-child,
.ant-search-input .ant-search-btn.active > a:only-child {
  color: currentColor;
}
.ant-search-input .ant-search-btn:active > a:only-child:after,
.ant-search-input .ant-search-btn.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input .ant-search-btn.disabled,
.ant-search-input .ant-search-btn[disabled],
.ant-search-input .ant-search-btn.disabled:hover,
.ant-search-input .ant-search-btn[disabled]:hover,
.ant-search-input .ant-search-btn.disabled:focus,
.ant-search-input .ant-search-btn[disabled]:focus,
.ant-search-input .ant-search-btn.disabled:active,
.ant-search-input .ant-search-btn[disabled]:active,
.ant-search-input .ant-search-btn.disabled.active,
.ant-search-input .ant-search-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f7f7f7;
  border-color: var(--sally-input-border);
}
.ant-search-input .ant-search-btn.disabled > a:only-child,
.ant-search-input .ant-search-btn[disabled] > a:only-child,
.ant-search-input .ant-search-btn.disabled:hover > a:only-child,
.ant-search-input .ant-search-btn[disabled]:hover > a:only-child,
.ant-search-input .ant-search-btn.disabled:focus > a:only-child,
.ant-search-input .ant-search-btn[disabled]:focus > a:only-child,
.ant-search-input .ant-search-btn.disabled:active > a:only-child,
.ant-search-input .ant-search-btn[disabled]:active > a:only-child,
.ant-search-input .ant-search-btn.disabled.active > a:only-child,
.ant-search-input .ant-search-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-search-input .ant-search-btn.disabled > a:only-child:after,
.ant-search-input .ant-search-btn[disabled] > a:only-child:after,
.ant-search-input .ant-search-btn.disabled:hover > a:only-child:after,
.ant-search-input .ant-search-btn[disabled]:hover > a:only-child:after,
.ant-search-input .ant-search-btn.disabled:focus > a:only-child:after,
.ant-search-input .ant-search-btn[disabled]:focus > a:only-child:after,
.ant-search-input .ant-search-btn.disabled:active > a:only-child:after,
.ant-search-input .ant-search-btn[disabled]:active > a:only-child:after,
.ant-search-input .ant-search-btn.disabled.active > a:only-child:after,
.ant-search-input .ant-search-btn[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input .ant-search-btn:hover,
.ant-search-input .ant-search-btn:focus,
.ant-search-input .ant-search-btn:active,
.ant-search-input .ant-search-btn.active {
  background: #fff;
}
.ant-search-input .ant-search-btn:hover {
  border-color: var(--sally-input-border);
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty,
.ant-search-input:hover .ant-search-btn-noempty {
  color: #fff;
  background-color: var(--sally-blue);
  border-color: var(--sally-blue);
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty > a:only-child {
  color: currentColor;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:hover,
.ant-search-input:hover .ant-search-btn-noempty:hover,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:focus,
.ant-search-input:hover .ant-search-btn-noempty:focus {
  color: #fff;
  background-color: var(--sally-blue-faded);
  border-color: var(--sally-blue-faded);
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:hover > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty:hover > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:focus > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty:focus > a:only-child {
  color: currentColor;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:hover > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty:hover > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:focus > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:active,
.ant-search-input:hover .ant-search-btn-noempty:active,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.active,
.ant-search-input:hover .ant-search-btn-noempty.active {
  color: #fff;
  background-color: var(--sally-blue-shaded);
  border-color: var(--sally-blue-shaded);
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:active > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty:active > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.active > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty.active > a:only-child {
  color: currentColor;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty:active > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty:active > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.active > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled,
.ant-search-input:hover .ant-search-btn-noempty.disabled,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled],
.ant-search-input:hover .ant-search-btn-noempty[disabled],
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:hover,
.ant-search-input:hover .ant-search-btn-noempty.disabled:hover,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:hover,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:hover,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:focus,
.ant-search-input:hover .ant-search-btn-noempty.disabled:focus,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:focus,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:focus,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:active,
.ant-search-input:hover .ant-search-btn-noempty.disabled:active,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:active,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:active,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled.active,
.ant-search-input:hover .ant-search-btn-noempty.disabled.active,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled].active,
.ant-search-input:hover .ant-search-btn-noempty[disabled].active {
  color: var(--sally-input-text);
  background-color: var(--sally-input-background);
  border-color: var(--sally-input-border);
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty.disabled > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled] > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty[disabled] > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:hover > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty.disabled:hover > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:hover > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:hover > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:focus > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty.disabled:focus > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:focus > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:focus > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:active > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty.disabled:active > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:active > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:active > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled.active > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty.disabled.active > a:only-child,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled].active > a:only-child,
.ant-search-input:hover .ant-search-btn-noempty[disabled].active > a:only-child {
  color: currentColor;
}
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty.disabled > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled] > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty[disabled] > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:hover > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty.disabled:hover > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:hover > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:hover > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:focus > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty.disabled:focus > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:focus > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:focus > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled:active > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty.disabled:active > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled]:active > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty[disabled]:active > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty.disabled.active > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty.disabled.active > a:only-child:after,
.ant-search-input.ant-search-input-focus .ant-search-btn-noempty[disabled].active > a:only-child:after,
.ant-search-input:hover .ant-search-btn-noempty[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-search-input .ant-select-combobox .ant-select-selection__rendered {
  margin-right: 29px;
}
.ant-input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: 28px;
  font-size: 12px;
  line-height: 1.5;
  color: var(--sally-input-text);
  background-color: var(--sally-input-background);
  background-image: none;
  border: 1px solid var(--sally-input-border);
  border-radius: 4px;
  transition: all .3s;
}
.ant-input::-moz-placeholder {
  color: var(--sally-input-placeholder-text);
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: var(--sally-input-placeholder-text);
}
.ant-input::-webkit-input-placeholder {
  color: var(--sally-input-placeholder-text);
}
.ant-input:hover {
  border-color: var(--sally-input-hover-border);
}
.ant-input:focus {
  border-color: var(--sally-input-focus-border);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
}
.ant-input-disabled {
  background-color: var(--sally-input-disabled-background);
  opacity: 1;
  cursor: not-allowed;
  color: var(--sally-input-disabled-text);
}
.ant-input-disabled:hover {
  border-color: var(--sally-input-disabled-border);
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all .3s, height 0s;
}
.ant-input-lg {
  padding: 6px 7px;
  height: 32px;
}
.ant-input-sm {
  padding: 1px 7px;
  height: 22px;
}
.ant-input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
.ant-input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.ant-input-group > [class*="col-"] {
  padding-right: 8px;
}
.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
}
.ant-input-group-addon {
  padding: 4px 7px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  background-color: var(--sally-input-background);
  border: 1px solid var(--sally-input-border);
  border-radius: 4px;
  position: relative;
  transition: all .3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -7px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  background-color: inherit;
  margin: -1px;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: var(--sally-blue);
}
.ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6px 7px;
  height: 32px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 1px 7px;
  height: 22px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 32px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 22px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: left;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact:before,
.ant-input-group.ant-input-group-compact:after {
  content: " ";
  display: table;
}
.ant-input-group.ant-input-group-compact:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-right-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
  z-index: auto;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-right-width: 0;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 1px;
}
.ant-input-group-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.ant-input-affix-wrapper .ant-input {
  z-index: 1;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--sally-blue-faded);
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  z-index: 2;
  line-height: 0;
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 7px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 7px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 24px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 24px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}

body {
  .ant-input-group.ant-input-group-compact {
    & > * {
      width: auto;

      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .ant-input-group-addon {
    padding: 4px 5px 4px 0;
    background-color: var(--sally-input-background);
    border: 1px solid var(--sally-input-border);
    font-size: 14px;
    color: var(--sally-black);

    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          margin-right: 0;
          margin-left: 0;
          padding-right: 10px;
        }
        .ant-select-arrow {
          right: 0;
          width: 12px;
          height: 12px;
          margin-top: -5px;
          background-image: url('/src/images/icon-arrow-down-grey-12x12.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 12px 12px;
        }
      }

      &.ant-select-focused {
        .ant-select-selection {
          border-color: transparent;
        }
      }
    }
  }
  label {
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
    color: var(--sally-grey-shaded);

    > em {
      font-style: normal;
      color: var(--sally-text);
    }
  }
  .ant-input-wrapper {
    .ant-input-group-addon {
      padding: 4px 7px;
    }
  }
  .ant-input-group-lg .ant-input, .ant-input, .ant-input-search.ant-input-affix-wrapper .ant-input {
    height: 38px;
    padding: 8px 15px;
    border: 1px solid var(--sally-input-border);
    font-size: 14px;
    font-weight: 500;
    color: var(--sally-input-text);

    &:hover {
      border-color: var(--sally-input-hover-border);
      box-shadow: 0 1px 2px 0 var(--sally-input-focus-shadow) inset;
    }
    &:focus {
      border-color: var(--sally-input-focus-border);
      font-weight: 500;
      color: var(--sally-input-text);
    }
  }
  .ant-input-search {
    .ant-input-suffix {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 9px;
      right: 10px;
      z-index: 2;
      display: block;
      width: 20px;
      height: 20px;
      background-image: url('/src/images/icon-search-darkgrey-20x20.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }
  .ant-input-number {
    width: 100%;
    height: 38px;
    border: 1px solid #dae1e8;

    .ant-input-number-handler-wrap {
      z-index: 3;
      width: 25px;
      background-color: #fdfdfd;
      border-left: 1px solid #dae1e8;

      .ant-input-number-handler {
        height: 19px;

        .ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
          left: 5px;
          color: var(--sally-grey-shaded);

          &:before {
            font-family: inherit !important;
          }
        }
        .ant-input-number-handler-up-inner {
          font-size: 28px;
          font-weight: 400;

          &:before {
            content: '\002B';
          }
        }
        .ant-input-number-handler-down-inner {
          font-size: 20px;
          font-weight: 700;

          &:before {
            content: '\2014';
          }
        }
        &:hover {
          height: 19px !important;
        }
      }
    }
    .ant-input-number-input-wrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;

      .ant-input-number-input {
        height: 36px;
        line-height: 36px;
        padding: 0 15px;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-black);
      }
    }
    &.ant-input-number-no-handler {
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
    &:hover {
      border-color: #cad1d9;
      box-shadow: 0 1px 2px 0 rgba(237, 240, 244, 1) inset;
    }
    &:focus {
      border-color: var(--sally-orange);

      .ant-input-number-input {
        font-weight: 500;
        color: var(--sally-black);
      }
    }
  }
  .has-error {
    label {
      color: var(--sally-red);
    }
    .ant-input-wrapper {
      position: relative;

      .ant-input {
        border-color: var(--sally-red);
      }
      &:not(.ant-input-group):after {
        content: '';
        position: absolute;
        top: 9px;
        right: 10px;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url('/src/images/icon-cross-red-20x20.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
      }
    }
    .ant-input-group {
      .ant-input {
        border-color: var(--sally-red);
      }
    }
    .ant-input-group-addon {
      .ant-select {
        .ant-select-selection {
          border-color: transparent;
        }
      }
    }
    .ant-form-explain {
      display: none;
      margin-top: 5px;
      font-size: 14px;
      color: var(--sally-red);
    }
  }
}
