.car-allowance {
    padding-top: 30px;
    padding-bottom: 50px;

    .car-allowance-history {
        float: right;
        margin-top: -30px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-paragraph-text);
    }
    .car-allowances-header {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }
    & > div, & > div > div {
        & + div {
            .car-allowances-header {
                margin-top: 30px;
            }
        }
    }
    .car-allowance-date {
        font-size: 14px;
        font-weight: 300;
        color: var(--sally-text);
    }
    .car-allowance-distance {
        font-size: 17px;
        font-weight: 500;
        color: var(--sally-text);
    }
    .car-allowance-description {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        font-weight: 300;
        color: var(--sally-text);
    }
    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: var(--sally-paragraph-text);
    }
    .car-allowances-add {
        position: fixed;
        right: 0;
        bottom: 62px;
        left: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
    }
    &.platform-ios {
        .car-allowances-add {
            bottom: 92px;
        }
    }
}
.employees-car-allowance-map {
    position: relative;
    overflow-y: hidden;
    max-width: 100%;
    width: 440px;
    height: 290px;
    border: 1px solid var(--sally-code-border);
    border-radius: 4px;

    .employees-car-allowance-map-inner {
        max-width: 100%;
        width: 440px;
        height: 340px;
        margin-top: -25px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        opacity: 0;
    }
}
