html {
  height: 100%;
}

body {
  overflow: hidden;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--sally-background);
  font-family: var(--sally-font-family), sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--sally-text);
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}

small {
  display: block;
  font-size: 10px;
  color: var(--sally-paragraph-text);
}

a {
  color: var(--sally-link);
  text-decoration: none;
}
a:hover {
  color: var(--sally-link-hover);
  text-decoration: underline;
}

input[type=text],
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
}

.ant-btn.ant-btn-block {
  display: block;
  width: 100%;
}

