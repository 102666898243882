/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-tooltip {
  position: absolute;
  z-index: 1060;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}

.ant-tooltip-inner {
  max-width: 250px;
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  min-height: 34px;
}

.ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 3px;
  border-width: 5px 5px 0;
  border-top-color: rgba(0, 0, 0, 0.75);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 16px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 16px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(0, 0, 0, 0.75);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 8px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 8px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(0, 0, 0, 0.75);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 8px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 3px;
  border-width: 0 5px 5px;
  border-bottom-color: rgba(0, 0, 0, 0.75);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 16px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 16px;
}

body .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  background-color: #3d4346;
  box-shadow: 0 2px 6px 0 rgba(58, 64, 68, 0.2);
  text-align: center;
  font-size: 14px;
}
body .ant-tooltip.ant-tooltip-placement-top .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-topRight .ant-tooltip-content .ant-tooltip-arrow {
  border-top-color: #3d4346;
}
body .ant-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-rightTop .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-rightBottom .ant-tooltip-content .ant-tooltip-arrow {
  border-right-color: #3d4346;
}
body .ant-tooltip.ant-tooltip-placement-bottom .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-content .ant-tooltip-arrow {
  border-bottom-color: #3d4346;
}
body .ant-tooltip.ant-tooltip-placement-left .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-leftTop .ant-tooltip-content .ant-tooltip-arrow, body .ant-tooltip.ant-tooltip-placement-leftBottom .ant-tooltip-content .ant-tooltip-arrow {
  border-left-color: #3d4346;
}

