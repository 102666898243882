/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: text-bottom;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--sally-toggle-checked-border);
}

.ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid var(--sally-toggle-checked-border);
  content: "";
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.ant-checkbox:hover:after,
.ant-checkbox-wrapper:hover .ant-checkbox:after {
  visibility: visible;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  border: 1px solid var(--sally-code-border);
  border-radius: 2px;
  background-color: var(--sally-background-layer-1);
  transition: all 0.3s;
}

.ant-checkbox-inner:after {
  -ms-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid var(--sally-switch-nubbin);
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.ant-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  content: " ";
  -ms-transform: scale(1);
  transform: scale(1);
  position: absolute;
  left: 2px;
  top: 5px;
  width: 8px;
  height: 1px;
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid var(--sally-switch-nubbin);
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--sally-blue);
  border-color: var(--sally-blue);
}

.ant-checkbox-disabled {
  cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  animation-name: none;
  border-color: var(--sally-switch-disabled-background);
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: var(--sally-code-border) !important;
  background-color: var(--sally-switch-disabled-background);
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  animation-name: none;
  border-color: var(--sally-code-border);
}

.ant-checkbox-disabled + span {
  color: var(--sally-switch-disabled-background);
  cursor: not-allowed;
}

.ant-checkbox-wrapper {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}

.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-checkbox-wrapper + span span,
.ant-checkbox + span span {
  padding-left: 29px;
}

.ant-checkbox-group {
  font-size: 12px;
}

.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}

.ant-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}

@media \0 screen {
  .ant-checkbox-checked .ant-checkbox-inner:before,
.ant-checkbox-checked .ant-checkbox-inner:after {
    font-family: "anticon";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e632";
    font-weight: bold;
    font-size: 8px;
    border: 0;
    color: --sally-switch-nubbin;
    left: 2px;
    top: 3px;
    position: absolute;
  }
}
body .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  background-color: var(--sally-switch-nubbin);
  border-color: var(--sally-switch-nubbin-shadow);
}
body .ant-checkbox .ant-checkbox-inner:after {
  top: 2px;
  left: 5px;
}
body .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner, body .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-border);
}

