.pay-slips {
    padding-top: 10px;

    .btn-group {
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
    }
    .pay-slips-header {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }
    & > div, & > div > div {
        & + div {
            .pay-slips-header {
                margin-top: 30px;
            }
        }
    }
    a, a:hover {
        text-decoration: none;

        .ant-card.ant-card-bordered {
            &, &:hover {
                .ant-card-body {
                    position: relative;
                    padding: 15px 20px;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 25px;
                        visibility: visible;
                        display: block;
                        width: 7px;
                        height: 13px;
                        margin-top: -6px;
                        background-image: url('/src/images/icon-arrow-right-grey-7x13.png');
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: 7px 13px;
                    }
                }
            }
        }
        & + a {
            .ant-card.ant-card-bordered {
                margin-top: 20px;
            }
        }
    }
    .pay-slip-amount {
        font-size: 17px;
        font-weight: 500;
        color: var(--sally-text);
    }
    .pay-slip-period {
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);

        & > * {
            display: inline-block;
            margin-left: 20px;

            &:after {
                content: '';
                display: inline-block;
                width: 18px;
                height: 14px;
                margin-left: 20px;
                background-image: url('/src/images/icon-arrow-right-blue-20x20.png');
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: 18px 10px;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: var(--sally-paragraph-text);
    }
}
