.ant-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid var(--sally-button-text-border);
  white-space: nowrap;
  line-height: 1.15;
  height: var(--sally-input-m-height);
  padding: 8px 12px 8px 12px;
  font-size: 12px;
  border-radius: var(--sally-radius-10);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  color: var(--sally-button-text-text);
  background-color: var(--sally-button-text-background);

  &, .icon-fill {
    fill: var(--sally-button-text-text);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  & > .anticon {
    line-height: 1;
  }

  &, &:active, &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled, &[disabled] {
    cursor: not-allowed;

    & > * {
      pointer-events: none;
    }
  }

  &.ant-btn-xxl {
    padding: 8px 20px 8px 20px;
    font-size: 14px;
    line-height: 18px;
    height: var(--sally-input-xxl-height);
    border-radius: var(--sally-radius-16);
  }
  &.ant-btn-xl {
    padding: 12px 16px 12px 16px;
    font-size: 14px;
    line-height: 18px;
    height: var(--sally-input-xl-height);
    border-radius: var(--sally-radius-12);
  }
  &.ant-btn-l {
    padding: 8px 16px 8px 16px;
    font-size: 14px;
    line-height: 18px;
    height: var(--sally-input-l-height);
    border-radius: var(--sally-radius-12);
  }

  & > a:only-child {
    color: inherit;
  }

  &:hover {
    background-color: var(--sally-button-text-hover-background);

    & > a:only-child {
      color: inherit;
    }
  }
  &:focus {
    border-color: var(--sally-button-text-focus-border);

    & > a:only-child {
      color: inherit;
    }
  }
  &:active, &.active {
    color: var(--sally-button-text-active-text);
    background-color: var(--sally-button-text-active-background);

    & > a:only-child {
      color: inherit;
    }

    .icon-fill {
      fill: var(--sally-button-text-active-text);
    }
  }
  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus,
  &.disabled:active, &[disabled]:active,
  &.disabled.active, &[disabled].active {
    color: var(--sally-button-text-disabled-text);
    background-color: var(--sally-button-text-disabled-background);
    border-color: var(--sally-button-text-border);

    & > a:only-child {
      color: inherit;
    }

    .icon-fill {
      fill: var(--sally-button-text-disabled-text);
    }
  }

  & > i, & > span {
    pointer-events: none;
  }

  &.ant-btn-loading {
    color: var(--sally-button-text-loading-text);
  }

  &.ant-btn-danger {
    color: var(--sally-button-text-danger-text);
    background-color: var(--sally-button-text-danger-background);

    .icon-fill {
      fill: var(--sally-button-text-danger-text);
    }

    &:hover {
      color: var(--sally-button-text-danger-hover-text);
      background-color: var(--sally-button-text-danger-hover-background);
      .icon-fill {
        fill: var(--sally-button-text-danger-hover-text);
      }
    }

    &:active, &.active {
      background-color: var(--sally-button-text-danger-active-background);
    }

    &.ant-btn-loading {
      color: var(--sally-button-text-danger-text);
      .icon-fill {
        fill: var(--sally-button-text-danger-text);
      }
    }
  }

  &.ant-btn-secondary {
    color: var(--sally-button-secondary-text);
    background-color: var(--sally-button-secondary-background);
    border-color: var(--sally-button-secondary-border);
    // box-shadow: 0 2px 4px 0 var(--sally-button-box-shadow);
      box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);

    .icon-fill {
      fill: var(--sally-button-secondary-text);
    }

    &:hover {
      background-color: var(--sally-button-secondary-hover-background);
    }

    &:focus {
      border-color: var(--sally-button-secondary-focus-border);
      background-color: var(--sally-button-secondary-focus-border);
      color: var(--sally-button-secondary-text);
          
    }

    &:active, &.active {
      background-color: var(--sally-button-secondary-active-background);
    }

    &.ant-btn-loading {
      color: var(--sally-button-secondary-loading-text);
      background-color: var(--sally-button-secondary-loading-background);

      .icon-fill {
        fill: var(--sally-button-secondary-loading-text);
      }
    }

    &.disabled, &[disabled],
    &.disabled:hover, &[disabled]:hover,
    &.disabled:focus, &[disabled]:focus,
    &.disabled:active, &[disabled]:active,
    &.disabled.active, &[disabled].active {
      color: var(--sally-button-secondary-disabled-text);
      background-color: var(--sally-button-secondary-background);
      border-color: var(--sally-button-secondary-border);

      .icon-fill {
        fill: var(--sally-button-secondary-disabled-text);
      }
    }

    &.ant-btn-danger {
      color: var(--sally-button-secondary-danger-text);
      background-color: var(--sally-button-secondary-danger-background);
      border-color: var(--sally-button-secondary-danger-border);

      .icon-fill {
        fill: var(--sally-button-secondary-danger-text);
      }

      &:hover {
        color: var(--sally-button-secondary-danger-hover-text);
        background-color: var(--sally-button-secondary-danger-hover-background);

        .icon-fill {
          fill: var(--sally-button-secondary-danger-hover-text);
        }
      }

      &:active, &.active {
        background-color: var(--sally-button-secondary-danger-active-background);
      }

      &.ant-btn-loading {
        background-color: var(--sally-button-secondary-danger-loading-background);
      }
    }
  }

  &.ant-btn-tertiary {
    color: var(--sally-button-tertiary-text);
    background-color: var(--sally-button-tertiary-background);
    border-color: var(--sally-button-tertiary-border);
    // box-shadow: 0 2px 4px 0 var(--sally-button-box-shadow);
      box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);

    .icon-fill {
      fill: var(--sally-button-tertiary-text);
    }

    &:hover {
      background-color: var(--sally-button-tertiary-hover-background);
    }

    &:focus {
      border-color: var(--sally-button-tertiary-focus-border);
    }

    &:active, &.active {
      background-color: var(--sally-button-tertiary-active-background);
    }

    &.ant-btn-loading {
      color: var(--sally-button-tertiary-loading-text);
      background-color: var(--sally-button-tertiary-loading-background);

      .icon-fill {
        fill: var(--sally-button-tertiary-loading-text);
      }
    }

    &.disabled, &[disabled],
    &.disabled:hover, &[disabled]:hover,
    &.disabled:focus, &[disabled]:focus,
    &.disabled:active, &[disabled]:active,
    &.disabled.active, &[disabled].active {
      color: var(--sally-button-tertiary-disabled-text);
      background-color: var(--sally-button-tertiary-background);
      border-color: var(--sally-button-tertiary-border);

      .icon-fill {
        fill: var(--sally-button-tertiary-disabled-text);
      }
    }

    &.ant-btn-danger {
      color: var(--sally-button-tertiary-danger-text);
      background-color: var(--sally-button-tertiary-danger-background);
      border-color: var(--sally-button-tertiary-danger-border);

      .icon-fill {
        fill: var(--sally-button-tertiary-danger-text);
      }

      &:hover {
        color: var(--sally-button-tertiary-danger-hover-text);
        background-color: var(--sally-button-tertiary-danger-hover-background);

        .icon-fill {
          fill: var(--sally-button-tertiary-danger-hover-text);
        }
      }

      &:active, &.active {
        background-color: var(--sally-button-tertiary-danger-active-background);
      }

      &.ant-btn-loading {
        background-color: var(--sally-button-tertiary-danger-loading-background);
      }
    }
  }

  &.ant-btn-primary {
    color: var(--sally-button-primary-text);
    background-color: var(--sally-button-primary-background);
    border-color: var(--sally-button-primary-border);
    // box-shadow: 0 1px 2px 0 var(--sally-button-box-shadow);
      box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);

    .icon-fill {
      fill: var(--sally-button-primary-text);
    }

    &:hover {
      color: var(--sally-button-primary-hover-text);
      background-color: var(--sally-button-primary-hover-background);

      .icon-fill {
        fill: var(--sally-button-primary-hover-text);
      }
    }

    &:focus {
      border-color: var(--sally-button-primary-focus-border);
    }

    &:active, &.active {
      color: var(--sally-button-primary-active-text);
      background-color: var(--sally-button-primary-active-background);

      .icon-fill {
        fill: var(--sally-button-primary-active-text);
      }
    }

    &.ant-btn-loading {
      color: var(--sally-button-primary-loading-text);
      background-color: var(--sally-button-primary-loading-background);

      .icon-fill {
        fill: var(--sally-button-primary-loading-text);
      }
    }

    &.disabled, &[disabled],
    &.disabled:hover, &[disabled]:hover,
    &.disabled:focus, &[disabled]:focus,
    &.disabled:active, &[disabled]:active,
    &.disabled.active, &[disabled].active {
      color: var(--sally-button-primary-disabled-text);
      background-color: var(--sally-button-primary-background);
      border-color: var(--sally-button-primary-border);

      .icon-fill {
        fill: var(--sally-button-primary-disabled-text);
      }
    }

    &.ant-btn-danger {
      color: var(--sally-button-primary-danger-text);
      background-color: var(--sally-button-primary-danger-background);

      .icon-fill {
        fill: var(--sally-button-primary-danger-text);
      }

      &:hover {
        background-color: var(--sally-button-primary-danger-hover-background);
      }

      &:active, &.active {
        background-color: var(--sally-button-primary-danger-active-background);
      }

      &.ant-btn-loading {
        color: var(--sally-button-primary-loading-text);
        background-color: var(--sally-button-primary-danger-loading-background);

        .icon-fill {
          fill: var(--sally-button-primary-loading-text);
        }
      }
    }
  }

  &.icon-form-button {
    margin-top: var(--sally-form-adjust-height);
    width: 100%;
    padding-left: 13px;

    .icon {
      float: none;
      margin-right: 0;
    }
  }

  &:not(.ant-btn-icon) {
    .ant-btn-prefix-icon {
      float: left;
      margin-right: 7px;
    }
    .ant-btn-suffix-icon {
      float: right;
      margin-left: 7px;
    }
  }
}
// *:not(.ant-col):has(> .ant-btn), .ant-btn {
//   & + *:not(.ant-col):has(> .ant-btn), & + .ant-btn {
//     margin-left: 10px;
//   }
// }