body .leave-statistics {
  .leave-statistics-header {
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  .leave-statistics-years-selector {
    margin-bottom: 5px;
  }
  .leave-statistics-explain-link {
    text-align: right;
    margin-bottom: 5px;
  }
  .ant-card.ant-card-bordered {
    .ant-card-body {
      padding: 15px 30px;

      .leave-statistics-card-header {
        font-size: 14pt;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .ant-row {
        & + .ant-row {
          margin-top: 5px;
        }

        .leave-statistics-card-days {
          text-align: right;
        }
      }
    }
  }
  .leave-statistics-disclaimer {
    margin-top: 10px;
    font-size: 10pt;
    font-style: italic;
  }
}