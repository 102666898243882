body {
    // Ageras colours
        --ageras-electric-green: #01ff94;
        --ageras-deep-sea-green: #002e33;
        --ageras-lavender: #dbd4ff;
        --ageras-day-grey: #edf1f7;
    
        --ageras-black: #000;
        --ageras-white: #fff;
    
        --ageras-green: #00e383;
        --ageras-green-140: #075e42;
        --ageras-green-120: #078e64;
        --ageras-green-110: #298574;
        --ageras-green-90: #32d583;
        --ageras-green-60: #6ce9a6;
        --ageras-green-20: #a6f4c5;
        --ageras-green-10: #d1fadf;
        --ageras-green-5: #e8fff0;
    
        --ageras-yellow: #fbdb3f;
        --ageras-yellow-220: #735714;
        --ageras-yellow-200: #8f7638;
        --ageras-yellow-120: #dcb11a;
        --ageras-yellow-30: #fff888;
        --ageras-yellow-10: #fff9cc;
    
        --ageras-red: #d92d20;
        --ageras-red-120: #951307;
        --ageras-red-110: #c94337;
        --ageras-red-100: #f4594e;
        --ageras-red-90: #f07870;
        --ageras-red-10: #f7ddd9;
        --ageras-red-5: #fff8f6;
    
        --ageras-purple: #5e45d2;
        --ageras-purple-110: #5d4dae;
        --ageras-purple-20: #f8f6ff;
    
        --ageras-blue-140: #131c3e;
        --ageras-blue-120: #1e267d;
        --ageras-blue-110: #2258ce;
        --ageras-blue-90: #5e8bef;
        --ageras-blue-60: #7ca2f8;
        --ageras-blue-10: #d0e6fe;
        --ageras-blue-5: #e4f1ff;
    
        --ageras-grey-200: #26282f;
        --ageras-grey-90: #697588;
        --ageras-grey-60: #a7a9b4;
        --ageras-grey-50: #d3d6df;
        --ageras-grey-40: #d9dce5;
        --ageras-grey-30: #e8e9f0;
        --ageras-grey-20: #f5f6f9;
        --ageras-grey-10: #f6f9ff;
    
        // Classic Salary colours
        --sally-green: #24973c;
        --sally-green-shaded: #1f8234;
        --sally-green-faded: #6acb7e;
        --sally-orange: #f69306;
        --sally-orange-shaded: #f8750c;
        --sally-orange-faded: #ffa72c;
        --sally-yellow: #f4b711;
        --sally-yellow-shaded: #f2ae0a;
        --sally-yellow-faded: #ffce3d;
        --sally-red: #fa581f;
        --sally-red-shaded: #fc6234;
        --sally-red-faded: #f46e65;
        --sally-grey: #adbbc8;
        --sally-grey-shaded: #748da6;
        --sally-grey-faded: #cdcdcd;
        --sally-blue: #108ee9;
        --sally-blue-shaded: #0e77ca;
        --sally-blue-faded: #67b3ff;
        --sally-black: #20445b;
        --sally-black-faded: #3e5461;
        --sally-white: #fff;
        --sally-white-shaded: #e5e5e5;
        --sally-white-faded: #f7f7f7;

        //Font
            font-family: var(--sally-font-family), sans-serif;

    .main-wrapper {
        position: fixed;
        top: 74px;
        top: calc(74px + env(safe-area-inset-top));
        right: 0;
        bottom: 62px;
        left: 0;
        overflow-y: scroll;
        z-index: 5;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        padding: 20px;
        background-color: var(--sally-background);

        &.no-footer {
            bottom: 0;
        }

        & > div {
            margin-right: auto;
            margin-left: auto;
            max-width: 768px;

            .ptr {
                position: relative;

                .ptr-loader {
                    position: absolute;
                    top: -50px;
                    left: 50%;
                    display: none;
                    width: 20px;
                    margin-left: -10px;
                }
                &.ptr-pulling, &.ptr-refreshing {
                    .ptr-loader {
                        display: block;
                    }
                }
            }
        }
        .ant-row, .ant-row-flex {
            margin: 0 -15px;

            > div {
                padding: 0 15px;
            }
            & + .ant-row, & + .ant-row-flex {
                margin-top: 30px;
            }
        }
        .release-info {
            margin: 20px 0 10px 0;
            text-align: center;
            font-size: 11px;
            color: #999;
        }
        &.platform-ios {
            bottom: 92px;
        }
    }
    .login-wrapper {
        position: fixed;
        top: 74px;
        top: calc(74px + env(safe-area-inset-top));
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
        width: 100%;
        padding: 20px;
        background-color: #eff3f6;

        & > div {
            margin-right: auto;
            margin-left: auto;
            max-width: 768px;
        }
        .release-info {
            margin: 20px 0 10px 0;
            text-align: center;
            font-size: 11px;
            color: #999;
        }
    }

    /*@media only screen and (max-width: 940px) and (min-width: 320px) {
        .main-wrapper {
            width: 100%;
            border-radius: 0;
        }
    }*/

    .ant-card {
        margin-bottom: 0;

        &.ant-card-bordered,
        &.ant-card-bordered:hover {
            border: 1px solid #dfe2e5;
            border-radius: 4px;
            box-shadow: none;

            .ant-card-body {
                padding: 30px;
            }
        }
        & + .ant-card {
            margin-top: 30px;
        }
    }

        //  .ant-btn {
        //      min-height: 36px;
        //      padding: 7px 20px 7px 14px;
        //      line-height: 20px;
        //      background-color: #fff;
        //      border-color: #dae1e8;
        //      box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);
        //      text-align: center;
        //      font-size: 12px;
        //      font-weight: 500;
        //      color: var(--sally-black);
    
        //      .icon {
        //          float: left;
        //          margin-right: 7px;
        //      }
    
        //      &:hover,
        //      &:focus {
        //          border-color: #dae1e8;
        //          box-shadow: 0 2px 4px 0 rgba(57, 85, 106, 0.06);
        //          color: var(--sally-black);
        //      }
    
        //      &:active {
        //          background-color: #f6f9fb;
        //          border-color: #dae1e8;
        //          color: var(--sally-black);
        //      }
    
        //      &.ant-btn-primary {
        //          background-color: var(--sally-orange);
        //          border-color: var(--sally-orange);
        //          box-shadow: none;
        //          font-size: 14px;
        //          color: #fff;
    
        //          &:hover,
        //          &:focus {
        //              background-color: var(--sally-orange-shaded);
        //              border-color: var(--sally-orange-shaded);
        //              box-shadow: none;
        //              color: #fff;
        //          }
    
        //          &:active {
        //              background-color: var(--sally-orange-shaded);
        //              border-color: var(--sally-orange-shaded);
        //              box-shadow: none;
        //              color: #fff;
        //          }
        //      }
    
        //      &.ant-btn-secondary {
        //          background-color: var(--sally-green);
        //          border-color: var(--sally-green);
        //          box-shadow: 0 2px 4px 0 rgba(133, 78, 37, 0.1);
        //          font-size: 14px;
        //          color: #fff;
    
        //          &:hover,
        //          &:focus {
        //              background-color: var(--sally-green-shaded);
        //              border-color: var(--sally-green-shaded);
        //              box-shadow: 0 2px 6px 0 rgba(133, 78, 37, 0.2);
        //              color: #fff;
        //          }
    
        //          &:active {
        //              background-color: var(--sally-green-shaded);
        //              border-color: var(--sally-green-shaded);
        //              color: #fff;
        //          }
        //      }
    
        //      &.ant-btn-danger {
        //          background-color: var(--sally-red);
        //          border-color: var(--sally-red);
        //          box-shadow: none;
        //          font-size: 14px;
        //          color: #fff;
    
        //          &:hover,
        //          &:focus {
        //              background-color: var(--sally-red-shaded);
        //              border-color: var(--sally-red-shaded);
        //              box-shadow: none;
        //              color: #fff;
        //          }
    
        //          &:active {
        //              background-color: var(--sally-red-shaded);
        //              border-color: var(--sally-red-shaded);
        //              box-shadow: none;
        //              color: #fff;
        //          }
        //      }
        //  }


    .ant-form {
        .form-error {
            margin-bottom: 5px;
            padding-left: 20px;
            background-image: url('/src/images/icon-cross-red-20x20.png');
            background-position: -4px center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            line-height: 20px;
            font-size: 14px;
            color: var(--sally-red);
        }
        .ant-row {
            margin-right: -10px;
            margin-left: -10px;

            > div {
                padding: 0 10px;

                > .ant-row {
                    margin-right: 0;
                    margin-left: 0;

                    > div {
                        padding: 0;
                    }
                }
            }
            & + .ant-row {
                margin-top: 15px;
            }
        }
        .ant-form-item {
            margin-bottom: 10px;

            .ant-form-item-control {
                line-height: inherit;

                .ant-form-extra {
                    display: block;
                    margin-top: 10px;
                }
                .ant-input-number {
                    margin-top: 0;
                }
                .ant-switch, .ant-radio-group {
                    margin-top: 9px;
                    margin-bottom: 9px;
                }
                .ant-field-wrapper {
                    position: relative;
                    width: 100%;
                    height: 38px;
                    background-color: #fff;

                    > * {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 2;
                        background-color: transparent;

                        &.ant-form-suffix {
                            bottom: 0;
                        }
                    }
                }
                .ant-form-suffix, .ant-field-wrapper.ant-form-suffix {
                    position: absolute;
                    top: auto;
                    right: 15px;
                    bottom: 5px;
                    left: auto;
                    z-index: 1;
                    height: 36px;
                    line-height: 36px;
                    color: var(--sally-input-placeholder-text);
                }
            }
            &.ant-form-item-hide-error {
                .ant-form-item-control-wrapper {
                    .ant-form-item-control.has-error {
                        label {
                            color: var(--sally-input-placeholder-text);
                        }
                        .ant-input {
                            border-color: var(--sally-input-border)
                        }
                        .ant-input-group-addon {
                            border-color: var(--sally-input-border);
                            color: var(--sally-input-text);
                        }
                        .ant-input-wrapper {
                            .ant-input {
                                border-color: var(--sally-input-border);
                            }
                            &:not(.ant-input-group):after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .ant-form-extra {
            color: var(--sally-input-placeholder-text);
        }
        .ant-btn[type="submit"] {
            display: block;
            width: 100%;
        }
        .ant-btn + .ant-btn {
            display: inline-block;
            margin-left: 16px;
        }
        .ant-form-warning {
            display: inline-block;
            padding: 2px 0 2px 35px;
            background-image: url('/src/images/icon-circle-info-orange-22x22.png');
            background-position: center left;
            background-repeat: no-repeat;
            background-size: 22px 22px;
            font-weight: 500;
            color: var(--sally-input-error-text);
        }
        &.show-detailed-errors {
            .has-error {
                .ant-form-explain {
                    display: block;
                }
            }
        }
    }
    .ant-input-group.ant-input-group-compact {
        & > * {
            width: auto;

            &:first-child {
                margin-right: 0;
            }
            &:last-child {
                margin-left: 0;
            }
        }
        &.country-code-phone-group {
            display: flex;
            align-content: stretch;
            width: 100%;

            & > * {
                display: block;

                &:first-child {
                    width: 80px;
                }
                &:last-child {
                    flex: 1 0 auto;
                    width: auto;
                }
            }
        }
    }
    .ant-input-group-addon {
        padding: 4px 5px 4px 0;
        background-color: var(--sally-input-background);
        border: 1px solid var(--sally-input-border);
        font-size: 14px;
        color: var(--sally-input-text);
    }
    .ant-checkbox {
        .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            background-color: var(--sally-input-background);
            border-color: var(--sally-input-border);

            &:after {
                top: 2px;
                left: 5px;
            }
        }
        &.ant-checkbox-checked, &.ant-checkbox-indeterminate {
            .ant-checkbox-inner {
                background-color: var(--sally-toggle-checked-background);
                border-color: var(--sally-toggle-checked-border);
            }
        }
    }
    label {
        display: block;
        margin-bottom: 3px;
        font-size: 14px;
        color: var(--sally-input-text);

        > em {
            font-style: normal;
            color: var(--sally-input-placeholder-text);
        }
    }
    .ant-input-wrapper {
        .ant-input-group-addon {
            padding: 4px 7px;
        }
    }
    .ant-input-group-lg .ant-input, .ant-input {
        height: 38px;
        padding: 8px 15px;
        border: 1px solid var(--sally-input-border);
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-input-text);

        &:hover {
            border-color: var(--sally-input-hover-border);
            box-shadow: 0 1px 2px 0 var(--sally-input-focus-shadow) inset;
        }
        &:focus {
            border-color: var(--sally-input-focus-border);
            font-weight: 500;
            color: var(--sally-input-text);
        }
    }
    .ant-input-number {
        width: 100%;
        height: 38px;
        border: 1px solid var(--sally-input-border);

        .ant-input-number-handler-wrap {
            z-index: 3;
            width: 25px;
            background-color: var(--sally-input-background);
            border-left: 1px solid var(--sally-input-border);

            .ant-input-number-handler {
                height: 19px;

                .ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
                    left: 5px;
                    color: var(--sally-input-placeholder-text);

                    &:before {
                        font-family: inherit !important;
                    }
                }
                .ant-input-number-handler-up-inner {
                    font-size: 28px;
                    font-weight: 400;

                    &:before {
                        content: '\002B';
                    }
                }
                .ant-input-number-handler-down-inner {
                    font-size: 20px;
                    font-weight: 700;

                    &:before {
                        content: '\2014';
                    }
                }
                &:hover {
                    height: 19px !important;
                }
            }
        }
        .ant-input-number-input-wrap {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;

            .ant-input-number-input {
                height: 36px;
                line-height: 36px;
                padding: 0 15px;
                background-color: transparent;
                font-size: 14px;
                font-weight: 500;
                color: var(--sally-input-text);
            }
        }
        &.ant-input-number-no-handler {
            .ant-input-number-handler-wrap {
                display: none;
            }
        }
        &:hover {
            border-color:var(--sally-input-hover-border);
            box-shadow: 0 1px 2px 0 var(--sally-input-focus-shadow) inset;
        }
        &:focus {
            border-color: var(--sally-input-focus-border);

            .ant-input-number-input {
                font-weight: 500;
                color: var(--sally-input-text);
            }
        }
    }
    .ant-cascader-picker {
        .ant-cascader-picker-label {
            padding: 0 35px 0 15px;
            font-size: 14px;
            font-weight: 500;
            color: var(---sally-input-text);
        }
        .ant-cascader-picker-arrow {
            right: 6px;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            background-image: url('/src/images/icon-arrows-grey-20x20.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;

            &:before {
                content: '';
            }
        }
    }
    .ant-cascader-picker .ant-cascader-picker-label,
    .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item {
        span {
            display: inline-block;
            padding-right: 20px;
            padding-left: 6px;
            background-image: url('/src/images/icon-arrow-right-grey-12x12.png');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 12px 12px;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                background: none;
            }
        }
    }
    .ant-switch-wrapper {
        .ant-switch {
            float: left;
            margin-top: 9px;
            margin-right: 15px;
            margin-bottom: 9px;
        }
        .ant-switch-text {
            line-height: 38px;
            font-size: 14px;
            font-weight: 500;
            color: var(--sally-toggle-text);
        }
        &.ant-switch-disabled-wrapper {
            .ant-switch-text {
                color: var(--sally-toggle-hover-text);
            }
        }
    }
    .ant-switch {
        height: 20px;
        min-width: 38px;
        line-height: 18px;
        background-color: var(--sally-switch-background);
        border-color: var(--sally-switch-background);
        border-radius: 18px;

        .ant-switch-inner {
            margin-right: 6px;
            margin-left: 22px;
        }
        &.ant-switch-checked {
            background-color: var(--sally-toggle-checked-background);
            border-color: var(--sally-toggle-checked-background);

            .ant-switch-inner {
                margin-right: 22px;
                margin-left: 6px;
            }
            &:hover {
                background-color: var(--sally-toggle-checked-hover-background);
                border-color: var(--sally-toggle-checked-hover-background);
            }
            &:after {
                margin-left: -17px;
            }
        }
        &.ant-switch-disabled {
            background-color: var(--sally-switch-disabled-background);
            border-color: var(--sally-switch-disabled-background);

            &:hover {
                background-color: var(--sally-switch-disabled-background);
                border-color: var(--sally-switch-disabled-background);
            }
            &:after {
                background-color: var(--sally-switch-background);
            }
        }
        &:hover {
            background-color: var(--sally-switch-hover-background);
            border-color: var(--sally-switch-hover-background);
        }
        &:after {
            width: 16px;
            height: 16px;
            border-radius: 16px;
        }
    }
    .ant-radio-wrapper {
        margin-right: 30px;
        font-size: 14px;
        color: var(--sally-switch-background);

        .ant-radio {
            .ant-radio-inner {
                width: 16px;
                height: 16px;
                background-color: var(--sally-switch-nubbin);
                border-color: var(--sally-switch-nubbin-shadow);

                &:after {
                    top: 4px;
                    left: 4px;
                    background-color: transparent;
                }
            }
            & + * {
                padding-left: 13px;
                padding-right: 0;
            }
        }
        .icon {
            margin-bottom: -5px;
        }
        &.ant-radio-wrapper-checked {
            font-weight: 500;
            color: var(---sally-toggle-checked-text);

            .ant-radio.ant-radio-checked {
                .ant-radio-inner {
                    background-color: var(--sally-toggle-checked-background);  
                    border-color: var(--sally-toggle-checked-border);

                    &:after {
                        background-color: var(--sally-switch-nubbin);
                    }
                }
            }
        }
        &:hover {
            .ant-radio {
                .ant-radio-inner {
                    border-color: var(--sally-toggle-checked-border);
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .ant-calendar-picker {
        .ant-calendar-range-picker.ant-input {
            padding-right: 34px;
        }
        .ant-calendar-picker-icon {
            right: 8px;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            background-image: url('/src/images/icon-calendar-grey-20x20.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;

            &:after {
                content: '';
            }
        }
    }
    .ant-upload {
        &.ant-upload-drag {
            border: 0;

            &.ant-upload-drag-hover:not(.ant-upload-disabled) {
                border: 0;
            }
        }
    }
    .has-error {
        label {
            color: var(--sally-status-warning-text-on-white);
        }
        .ant-input-wrapper {
            position: relative;

            .ant-input {
                border-color: var(--sally-status-failure-border);
            }
            &:not(.ant-input-group):after {
                content: '';
                position: absolute;
                top: 9px;
                right: 10px;
                display: block;
                width: 20px;
                height: 20px;
                background-image: url('/src/images/icon-cross-red-20x20.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 20px 20px;
            }
        }
        .ant-input-group {
            .ant-input {
                border-color: var(--sally-status-failure-border);
            }
        }
        .ant-form-explain {
            display: none;
            margin-top: 5px;
            font-size: 14px;
            color: var(--sally-status-failure-border);
        }
    }

    .ant-alert {
        padding: 12px 44px;
        margin-bottom: 30px;
        background-position: 12px center;
        background-repeat: no-repeat;
        background-size: 22px 22px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-status-failure-text);

        > .anticon {
            display: none;
        }
        .ant-alert-close-icon {
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background-image: url('/src/images/icon-cross-white-10x10.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 10px 10px;

            > .anticon {
                display: none;
            }
        }
      
    }
    .btn-group {
        .ant-btn {
            padding-right: 14px;
            border-color: var(--sally-button-secondary-focus-border);
    

            &.ant-btn-secondary {
                font-size: 12px;
            }
        }
        * {
            &:first-child .ant-btn, &.ant-btn:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child.ant-btn, &.ant-btn:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.registrations-list {
    div.registration-row {
        .ant-card.ant-card-bordered {
            &, &:hover {
                &.registration-row-unapproved {
                    .ant-card-body {
                        border-color: var(--sally-status-awaiting-system-border);
                    }
                }
                &.registration-row-approved {
                    .ant-card-body {
                        border-color: var(--sally-status-success-border);
                    }
                }
                .ant-card-body {
                    position: relative;
                    padding: 0 20px 0 0;
                    border-left: 6px solid transparent;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;

                    .registration-row-info {
                        padding: 15px 0 15px 14px;

                        &:not(.no-click) {
                            cursor: pointer;
                        }
                    }

                    .registration-row-delete {
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        visibility: visible;
                        display: block;
                        width: 61px;
                        height: 61px;
                        margin-top: -30px;
                        background-image: url('/src/images/icon-cross-grey-11x11.png');
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: 11px 11px;
                        cursor: pointer;
                    }
                }
            }
        }
        & + div.registration-row {
            .ant-card.ant-card-bordered {
                margin-top: 20px;
            }
        }
    }
}

.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft {
    border: 1px solid var(--sally-input-border);
    box-shadow: var(--sally-input-focus-shadow);
    font-size: 14px;

    .ant-cascader-menu {
        border-right: 1px solid #dae1e8;

        .ant-cascader-menu-item {
            padding: 8px 30px 8px 15px;
            border-bottom: 1px solid var(--sally-input-border);
            font-weight: 500;
            color: var(--sally-input-text);

            &:after {
                content: ' ';
                top: 13px;
                right: 10px;
                transform: scale(1);
                width: 12px;
                height: 12px;
                background-image: url('/src/images/icon-arrow-right-grey-12x12.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 12px 12px;
            }
            &.ant-cascader-menu-item-active {
                background-color: var(--sally-input-background);
            }
            &:hover {
                background-color: var(--sally-input-background);
            }
        }
    }
}

 .registrations-add {
     position: fixed;
     right: 0;
     bottom: 62px;
     left: 0;
     height: 50px;
     width: 100%;
     border: none;
     text-align: center;
     font-size: 17px;
     font-weight: 600;
     cursor: pointer;
     border-radius: 0%;

    &:hover {
            text-decoration: underline;
        }
 }

 .platform-ios {
     .registrations-add {
         bottom: 92px;
     }
 }
