.swipe {
  padding-top: 30px;
  padding-bottom: 50px;
}
.swipe .swipe-introduction .swipe-history {
  float: right;
  margin-top: -30px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-button-primary-background);
}
.swipe .swipe-introduction .swipe-amount-container {
  padding-top: 30px;
}
.swipe .swipe-introduction .swipe-amount-container .swipe-amount-max, .swipe .swipe-introduction .swipe-amount-container .swipe-amount-min {
  font-size: 24px;
}
.swipe .swipe-introduction .swipe-amount-container .swipe-amount-min {
  padding-bottom: 15px;
}
.swipe .swipe-introduction .swipe-amount-container .swipe-amount-max {
  cursor: pointer;
  float: right;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider {
  appearance: none;
  width: 100%;
  height: 1rem;
  background: transparent;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider:active::-webkit-slider-thumb {
  appearance: none;
  margin-top: -7px;
  border: 0;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider:active::-moz-range-thumb {
  border: 0;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider:active::-ms-thumb {
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider:focus {
  outline: none;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-webkit-slider-thumb {
  appearance: none;
  margin-top: -7px;
  border: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--sally-header-submenu-background);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-moz-range-thumb {
  border: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--sally-header-submenu-background);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-ms-thumb {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--sally-header-submenu-background);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-webkit-slider-runnable-track {
  padding: 0.25rem;
  height: 0.1rem;
  box-sizing: content-box;
  border-radius: 3rem;
  background-color: var(--sally-background);
  border: 0;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-moz-range-track {
  padding: 0.25rem;
  height: 0.1rem;
  box-sizing: content-box;
  border-radius: 3rem;
  background-color: var(--sally-code-border);
  border: 0;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-moz-focus-outer {
  border: 0;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-ms-track {
  border: 0;
  padding: 0.25rem;
  height: 0.1rem;
  box-sizing: content-box;
  border-radius: 3rem;
  background-color: var(--sally-code-border);
  color: transparent;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-ms-fill-lower, .swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-ms-fill-upper {
  background-color: transparent;
}
.swipe .swipe-introduction .swipe-amount-container .select-amount-slider::-ms-tooltip {
  display: none;
}
.swipe .swipe-introduction .swipe-amount-container .swipe-amount-description {
  margin-top: 10px;
  text-align: center;
}
.swipe .swipe-slider-container {
  position: fixed;
  bottom: 62px;
  width: 90%;
  max-width: 768px;
  margin-bottom: 20px;
}
.swipe .swipe-slider-container .swipe-above-limit {
  padding-bottom: 10px;
  color: var(--sally-input-error-border);
}
.swipe .swipe-slider-container .swipe-fee-note {
  font-size: 12pt;
}
.swipe .swipe-slider-container .slider-container {
  height: 80px;
  padding-bottom: 48px;
  font-size: 24px;
  text-align: center;
  color: var(--sally-background);
}
.swipe .swipe-slider-container .slider-container .slider-text {
  position: relative;
  top: -56px;
  padding-left: 30px;
}
.swipe .swipe-slider-container p {
  margin-top: 5px;
  text-align: center;
}
.swipe.platform-ios .swipe-slider-container {
  bottom: 92px;
}

.swipes {
  padding-top: 30px;
}
.swipes .swipe-header {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
.swipes > div + div .swipe-header, .swipes > div > div + div .swipe-header {
  margin-top: 30px;
}
.swipes .swipe-date {
  font-size: 14px;
  font-weight: 300;
  color: var(--sally-paragraph-text);
}
.swipes .swipe-description {
  font-size: 17px;
  font-weight: 500;
  color: var(--sally-text);
}
.swipes .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}
.swipes .swipes-start {
  position: fixed;
  right: 0;
  bottom: 62px;
  left: 0;
  height: 50px;
  background-color: var(--sally-button-primary-text);
  line-height: 50px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: var(--sally-button-primary-text);
}
.swipes.platform-ios .swipes-start {
  bottom: 92px;
}

.swipe-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--sally-button-primary-background);
  color: var(--sally-button-primary-text);
  text-align: center;
  padding: 15px;
}
.swipe-overlay .swipe-overlay-title {
  font-size: 25pt;
  font-weight: bold;
  line-height: 30pt;
}
.swipe-overlay .swipe-overlay-text {
  font-size: 12pt;
  color: var(--sally-button-secondary-danger-hover-text);
}
.swipe-overlay .swipe-overlay-button, .swipe-overlay .swipe-overlay-button:hover {
  margin-top: 40px;
  background-color: var(--sally-button-primary-text);
  border-color: var(--sally-button-primary-text);
  font-weight: bold;
  font-size: 15pt;
  letter-spacing: 0.25pt;
  color: var(--sally-button-primary-background);
  width: 100%;
}
.swipe-overlay .swipe-overlay-button:hover a {
  text-decoration: none;
}

