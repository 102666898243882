body .reimbursement-vouchers {
    padding-top: 30px;
    padding-bottom: 50px;

    .reimbursement-vouchers-header {
        margin-top: -30px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }
    & > div, & > div > div {
        & + div {
            .reimbursement-vouchers-header {
                margin-top: 30px;
            }
        }
    }
    div.registration-row .ant-card.ant-card-bordered .ant-card-body {
        .registration-row-info {
            min-height: 80px;
            padding-top: 10px;
            padding-bottom: 5px;
        }

        .reimbursement-voucher-thumbnail {
            float: left;
            max-width: 80px;
            max-height: 80px;
            margin: -10px 20px -15px -14px;
        }

        .reimbursement-voucher-date {
            font-size: 14px;
            font-weight: 300;
            color: var(--sally-paragraph-text);
        }

        .reimbursement-voucher-headline {
            font-size: 17px;
            font-weight: 500;
            color: var(--sally-text);
        }

        .reimbursement-voucher-description {
            font-size: 12px;
            color: var(--sally-paragraph-text);
        }

        .content-text {
            margin-bottom: 20px;
            text-align: center;
            font-size: 12px;
            color: var(--sally-paragraph-text);
        }
    }
    // .reimbursement-vouchers-add {
    //     position: fixed;
    //     right: 0;
    //     bottom: 62px;
    //     left: 0;
    //     height: 50px;
    //     background-color: var(--sally-orange);
    //     line-height: 50px;
    //     text-align: center;
    //     font-size: 17px;
    //     font-weight: 600;
    //     color: var(--sally-white);
    //     cursor: pointer;

    //     &:hover {
    //         text-decoration: underline;
    //     }
    // }
    // &.platform-ios {
    //     .reimbursement-vouchers-add {
    //         bottom: 92px;
    //     }
    // }
}
