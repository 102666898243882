.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    background: var(--sally-header-background);
    box-shadow: 0 1px 3px 0 rgba(39, 145, 7, 0.1);
    height: 74px;
    height: calc(74px + env(safe-area-inset-top));
    margin: 0;
    padding: 0 20px;
    padding-top: env(safe-area-inset-top);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.header-logo {
    display: block;
    margin: auto auto;

    svg {
        width: 150px;
        height: auto;
        display: block;
            margin: auto auto;


        .logo-fill {
            fill: var(--sally-logo-text);
        }
    }

}

.header-logo>img {
    width: 100px;
    margin: 25px 0 0 0;


}

.back {
    position: absolute;
    top: 20px;
    top: calc(20px + env(safe-area-inset-top));
    left: 20px;
    padding: 6px;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;

    }
}

.profile {
    position: absolute;
    top: 20px;
    top: calc(20px + env(safe-area-inset-top));
    right: 20px;
}