/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-message {
  font-size: 12px;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  left: 0;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice:first-child {
  margin-top: -8px;
}

.ant-message-notice-content {
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background: var(--sally-background-layer-1);
  display: inline-block;
  pointer-events: all;
}

.ant-message-success .anticon {
  color: var(--sally-status-success-text-on-white);
}

.ant-message-error .anticon {
  color: var(--sally-status-failure-text-on-white);
}

.ant-message-warning .anticon {
  color: var(--sally-status-warning-text-on-white);
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: var(--sally-status-info-text-on-white);
}

.ant-message .anticon {
  margin-right: 8px;
  font-size: 14px;
  top: 1px;
  position: relative;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  animation-name: MessageMoveOut;
  overflow: hidden;
  animation-duration: 0.3s;
}

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }
  100% {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

