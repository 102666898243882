.time-registration {
    padding-top: 30px;
    padding-bottom: 50px;

    .time-registration-summary {
        margin-top: -30px;
        margin-bottom: 9px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }

    .time-registration-history {
        float: right;
        margin-top: -30px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-text);
    }

    .time-registrations-header {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-paragraph-text);
    }

    &>div,
    &>div>div {
        &+div {
            .time-registrations-header {
                margin-top: 30px;
            }
        }
    }

    .time-registration-date {
        font-size: 14px;
        font-weight: 300;
        color: var(--sally-paragraph-text);
    }

    .time-registration-description {
        font-size: 17px;
        font-weight: 500;
        color: var(--sally-text);
    }

    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: var(--sally-paragraph-text);
    }

    .time-registrations-add {
        position: fixed;
        right: 0;
        bottom: 62px;
        left: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 17px;
        font-weight: 600;

    }

    &.platform-ios {
        .time-registrations-add {
            bottom: 92px;
        }
    }
}