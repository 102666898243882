body .dashboard {
    .dashboard-controls {
        text-align: center;
        margin-bottom: 20px;

        .dashboard-choice-switcher-toggle {
            margin-left: 25px;
        }
    }
    .content-text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: var(--sally-grey);
    }
    .dashboard-choice-card.ant-card.ant-card-bordered {
        max-height: 50vh;

        .dashboard-choice-switcher-toggle {
            padding: 2px 8px;
            position: absolute;
            top: 10px;
            right: 15px;
        }

        .dashboard-choice-register-button, .dashboard-choice-page-button {
            width: 100%;
            margin-top: 15px;
        }

        & > .ant-card-body {
            padding-right: 24px;
            padding-left: 24px;

            .card-default {
                padding-right: 6px;
                padding-left: 6px;

                .h3 {
                    margin: -7px 0 0 0;
                    font-size: 17px;
                }
                p {
                    margin: 0 0 20px 0;
                    font-size: 12px;
                    color: var(--sally-paragraph-text);
                }
                .stat-large {
                    display: inline-block;
                    line-height: 40px;
                    font-size: 40px;
                    font-weight: 300;
                    color: var(--sally-text);
                }
                @media only screen and (max-height: 600px)  {
                    p {
                        margin: 0;
                    }
                }
                .stat-small {
                    margin-top: 5px;
                    line-height: 19px;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--sally-paragraph-text);
                }
            }
        }
    }
    .card-work-hours {
        .ant-form .work-hours-day-form > .ant-row > div {
            padding: 0 1px;

            &:first-child {
                padding-left: 10px;
            }
            &:last-child {
                padding-right: 10px;
            }
        }
    }
    .swipe-link .ant-btn {
        width: 100%;
        margin-top: 15px;
        font-size: 20pt;
        white-space: inherit;
        height: 60px;
        text-align: center;
        line-height: 20pt;
        // background-color: #ffa800;
        // color: #f4f4f4;
    }
    .stat-pay-toggle {
        display: none;
    }
    @media only screen and (min-aspect-ratio: 1/1)  {
        .card-default:not(.force-show-pay) {
            .stat-pay {
                display: none;
            }
        }
        .stat-pay-toggle {
            display: block;
            float: right;
        }
    }
}
