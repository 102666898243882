/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 22px;
  min-width: 44px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 20px;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-switch-inner {
  color: var(--sally-switch-nubbin);
  font-size: 12px;
  margin-left: 24px;
  margin-right: 6px;
  display: block;
}

.ant-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 1px;
  top: 1px;
  border-radius: 18px;
  background-color: var(--sally-switch-nubbin);
  content: " ";
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-switch:active:after {
  width: 24px;
}

.ant-switch:focus {
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
  outline: 0;
}

.ant-switch:focus:hover {
  box-shadow: none;
}

.ant-switch-small {
  height: 14px;
  min-width: 28px;
  line-height: 12px;
}

.ant-switch-small .ant-switch-inner {
  margin-left: 18px;
  margin-right: 3px;
}

.ant-switch-small:after {
  width: 12px;
  height: 12px;
  top: 0;
  left: 0.5px;
}

.ant-switch-small:active:after {
  width: 16px;
}

.ant-switch-small.ant-switch-checked:after {
  left: 100%;
  margin-left: -12.5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-left: 3px;
  margin-right: 18px;
}

.ant-switch-small:active.ant-switch-checked:after {
  margin-left: -16.5px;
}

.ant-switch-checked {
  background-color: var(--sally-toggle-checked-background);
}

.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
  margin-right: 24px;
}

.ant-switch-checked:after {
  left: 100%;
  margin-left: -19px;
}

.ant-switch-checked:active:after {
  margin-left: -25px;
}

.ant-switch-disabled {
  cursor: not-allowed;
  background: var(sally-switch-disabled-background);
}

.ant-switch-disabled:after {
  background: var(--sally-switch-background);
  cursor: not-allowed;
}

.ant-switch-disabled .ant-switch-inner {
  color: var(--sally-switch-checked-nubbin-shadow);
}

body .ant-switch-wrapper .ant-switch {
  float: left;
  margin-top: 9px;
  margin-right: 15px;
  margin-bottom: 9px;
}
body .ant-switch-wrapper .ant-switch-text {
  line-height: 38px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-toggle-text);
}
body .ant-switch-wrapper.ant-switch-disabled-wrapper .ant-switch-text {
  color: var(--sally-paragraph-text);
}
body .ant-switch {
  height: 20px;
  min-width: 38px;
  line-height: 18px;
  background-color: var(--sally-switch-background);
  border-color: var(--sally-switch-background);
}
body .ant-switch .ant-switch-inner {
  margin-right: 6px;
  margin-left: 22px;
}
body .ant-switch.ant-switch-checked {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-background);
}
body .ant-switch.ant-switch-checked v .ant-switch-inner {
  margin-right: 22px;
  margin-left: 6px;
}
body .ant-switch.ant-switch-checked:hover {
  background-color: var(--sally-toggle-checked-hover-background);
  border-color: var(--sally-toggle-checked-hover-background);
}
body .ant-switch.ant-switch-checked:after {
  margin-left: -17px;
}
body .ant-switch.ant-switch-disabled {
  background-color: var(--sally-switch-disabled-background);
  border-color: var(--sally-switch-disabled-background);
}
body .ant-switch.ant-switch-disabled:hover {
  background-color: var(--sally-switch-disabled-background);
  border-color: var(--sally-switch-disabled-background);
}
body .ant-switch.ant-switch-disabled:after {
  background-color: var(--sally-switch-nubbin);
}
body .ant-switch:hover {
  background-color: var(-sally-switch-hover-background);
  border-color: var(-sally-switch-hover-background);
}
body .ant-switch:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}

