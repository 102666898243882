.salary-registration {
  padding-top: 30px;
  padding-bottom: 50px;
}
.salary-registration .salary-registration-summary {
  margin-top: -30px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
.salary-registration .salary-registration-history {
  float: right;
  margin-top: -30px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
.salary-registration .salary-registrations-header {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-paragraph-text);
}
.salary-registration > div + div .salary-registrations-header, .salary-registration > div > div + div .salary-registrations-header {
  margin-top: 30px;
}
.salary-registration .salary-registration-date {
  font-size: 14px;
  font-weight: 300;
  color: var(--sally-paragraph-text);
}
.salary-registration .salary-registration-description {
  font-size: 17px;
  font-weight: 500;
  color: var(--sally-text);
}
.salary-registration .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}
.salary-registration .salary-registrations-add {
  position: fixed;
  right: 0;
  bottom: 62px;
  left: 0;
  height: 50px;
  background-color: var(--sally-button-primary-background);
  line-height: 50px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: var(--sally-button-primary-text);
}
.salary-registration.platform-ios .salary-registrations-add {
  bottom: 92px;
}

