.h1 {
    line-height: 40px;
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 400;
    color: var(--sally-text);
}

.h2, .h3, .h4, .h5, .h6 {
    clear: both;
    margin: 0;
    font-weight: 400;
    color: var(--sally-paragraph-text);
}

.h1 {
    line-height: 36px;
    font-size: 22px;
}
.h2 {
    line-height: 28px;
    font-size: 18px;
}
.h3 {
    line-height: 24px;
    font-size: 16px;
}
.h5 {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: var(--sally-paragraph-text);
}

.ant-popover.context-menu-popover {
    top: calc(100vh - 180px) !important;
    left: 50vw !important;
    margin-top: -5px;
    margin-right: 10px;
    padding-top: 0;

    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        background-color: var(--sally-background-layer-1);
        background-clip: border-box;
        border: 1px solid var(--sally-code-border);
        border-radius: 3px;
        box-shadow: 0 2px 6px 0 var(--sally-menu-shadow);

        .ant-popover-inner-content {
            min-width: 175px;
            padding: 0;
            color: inherit;

            > * {
                display: block;
                line-height: 24px;
                padding: 6px 18px 6px 12px;
                border-bottom: 1px solid var(--sally-input-border);

                &, a {
                  font-size: 14px;
                  font-weight: 500;
                  color: var(--sally-text);
                }
                .icon {
                    float: left;
                    margin: 2px 10px 0 0;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.user-image {
    display: inline-block;
    background-color: var(--sally-header-user-image-background);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--sally-header-user-image-text);

    &.user-image-xsmall {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 10px;
    }
    &.user-image-small {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
    }
    &.user-image-medium {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
    }
    &.user-image-large {
        width: 106px;
        height: 106px;
        line-height: 106px;
        font-size: 47px;
    }
}

.help-modal-toggle {
    cursor: help;
    display: inline-block;
    margin-left: 8px;
    border: 1px solid #000;
    border-radius: 14px;
    padding-left: 10px;
    padding-right: 10px;

    svg {
        width: 14px;
        height: 14px;
    }
}