body .reimbursement-vouchers {
  padding-top: 30px;
  padding-bottom: 50px;
}
body .reimbursement-vouchers .reimbursement-vouchers-header {
  margin-top: -30px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-text);
}
body .reimbursement-vouchers > div + div .reimbursement-vouchers-header, body .reimbursement-vouchers > div > div + div .reimbursement-vouchers-header {
  margin-top: 30px;
}
body .reimbursement-vouchers div.registration-row .ant-card.ant-card-bordered .ant-card-body .registration-row-info {
  min-height: 80px;
  padding-top: 10px;
  padding-bottom: 5px;
}
body .reimbursement-vouchers div.registration-row .ant-card.ant-card-bordered .ant-card-body .reimbursement-voucher-thumbnail {
  float: left;
  max-width: 80px;
  max-height: 80px;
  margin: -10px 20px -15px -14px;
}
body .reimbursement-vouchers div.registration-row .ant-card.ant-card-bordered .ant-card-body .reimbursement-voucher-date {
  font-size: 14px;
  font-weight: 300;
  color: var(--sally-paragraph-text);
}
body .reimbursement-vouchers div.registration-row .ant-card.ant-card-bordered .ant-card-body .reimbursement-voucher-headline {
  font-size: 17px;
  font-weight: 500;
  color: var(--sally-text);
}
body .reimbursement-vouchers div.registration-row .ant-card.ant-card-bordered .ant-card-body .reimbursement-voucher-description {
  font-size: 12px;
  color: var(--sally-paragraph-text);
}
body .reimbursement-vouchers div.registration-row .ant-card.ant-card-bordered .ant-card-body .content-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--sally-paragraph-text);
}

